import { Box, Button, Container, Typography } from '@mui/material';
import React from "react";
import { useLocation } from "react-router-dom";
import CustomMarkdown from "../Components/CustomMarkdown";

export default function QuizResultQuestionDetails()
{
    const { state } = useLocation();
    const question = state.question;

    const feedbackUrl = `mailto:info@pathquestionbank.com?subject=Feedback on ${question.name}`;

    return (
        <Container maxWidth="md" sx={{ mt: 4, alignItems: 'centre' }}>

            <Typography align="left" component="h2" variant="h5" gutterBottom color="primary">
                Question
            </Typography>

            <Typography align="left" sx={{ userSelect: "none", mt: 2, mb: 1 }}>
                {question.text}
            </Typography>

            {question.questionAnswers.map((answer, index) => (
                <Box key={index} sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column'}}>
                    {answer.subQuestionText &&
                        <Typography align="left" sx={{ userSelect: "none", mt: 4 }}>
                            {answer.subQuestionText}
                        </Typography>
                    }

                    <Typography sx={{ userSelect: "none", mt: 1 }}>
                        <span style={{ color: "#56AA64" }}>Answer: </span>{answer.answer}
                    </Typography>
                </Box>
            ))}

            <Typography sx={{ mt: 4 }} align="left" component="h2" variant="h5" gutterBottom color="primary">
                Explanation
            </Typography>

            <CustomMarkdown children={question.information} />

            <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', mt: 4, mb: 4 }} >
                <Typography align="center" variant="caption">
                    Think you can improve this question?
                </Typography>
                <Typography align='center'>
                    <Button component="a" href={feedbackUrl} color='primary' size='large'>Give Feedback</Button>
                </Typography>
            </Box>

        </Container>
    );
}