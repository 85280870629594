import ArrowUpwardRoundedIcon from '@mui/icons-material/ArrowUpwardRounded';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import KeyboardBackspaceRoundedIcon from '@mui/icons-material/KeyboardBackspaceRounded';
import { Alert, Box, Button, Checkbox, Collapse, Container, FormControl, FormControlLabel, Grid, IconButton, InputLabel, List, ListItemButton, ListItemIcon, ListItemText, MenuItem, Paper, Radio, RadioGroup, Select, Typography } from "@mui/material";
import { makeStyles } from '@mui/styles';
import axios from "axios";
import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import LoadingSpinnerCentred from "../Components/LoadingSpinnerCentred";

const quizSizes = 
[
    { value: 5, label: 'Mini Test (5 questions)' },
    { value: 20, label: 'Regular Test (20 questions)' },
    { value: 40, label: 'Long Test (40 questions)' }
]

const useStyles = makeStyles((theme) => ({
    icon: { minWidth: '0px' }
}));

// TODO - deduplicate, also in HistoryPage.js QuizResultDetails.js
//const _sameQuizRetakeOption = 0;
const _differentQuestionsRetakeOption = 1; // default
const _preferPreviouslyAnswered = 2;
const _preferPreviouslyAnsweredIncorrectly = 3;

const filterOptions =
[
    { value: _differentQuestionsRetakeOption, label: "Prefer unanswered questions" },
    { value: _preferPreviouslyAnswered, label: "Prefer answered questions" },
    { value: _preferPreviouslyAnsweredIncorrectly, label: "Prefer incorrectly answered questions" },
]

export default function QuizConfig(params)
{
    const { user, onFinishedConfig } = params;
    const [quizSize, setQuizSize] = useState(20);
    const [categories, setCategories] = useState(null);
    const [questionFilter, setQuestionFilter] = useState(_differentQuestionsRetakeOption);
    const [alertInfo, setAlertInfo] = useState(null);
    const [submitting, setSubmitting] = useState(false);

    const classes = useStyles();
    let navigate = useNavigate();

    useEffect(() =>
    {
        setCategories(null);
        axios.get('api/categories/config', { withCredentials: true }).then(result =>
        {
            const newCategories = result.data.map(c => {
                return {
                    id: c.id, label: c.label, open: false,
                    subCategories: c.subCategories.map(sc => { return { id: sc.id, label: sc.label, checked: true } })
                };
            });

            setCategories(newCategories);
        })
        .catch((error) =>
        {
            if (error.response.status === 401)
                navigate("/");
        });
    }, [navigate]);

    function toggleOpen(event, index)
    {
        event.preventDefault();
        updateItem(index, (item) => item.open = !item.open);
    }

    function toggleChecked(index)
    {
        updateItem(index, (item) =>
        {
            const allSelected = item.subCategories.every(sc => sc.checked);
            item.subCategories = item.subCategories.map(sc => {
                sc.checked = !allSelected;
                return sc;
            });
        });
    }

    function toggleSubCategoryChecked(index, subIndex)
    {
        updateItem(index, (item) => item.subCategories[subIndex].checked = !item.subCategories[subIndex].checked);
    }

    function updateItem(index, editAction)
    {
        let itemsCopy = categories;
        let item = { ...itemsCopy[index] };
        editAction(item);
        itemsCopy[index] = item;
        setCategories([...itemsCopy]);
    }

    function onSubmit()
    {
        setSubmitting(true);
        const filteredCategories = categories.map(c =>
        {
            return { id: c.id, label: c.label,
                subCategories: c.subCategories.filter(sc => sc.checked).map(sc => { return { id: sc.id, label: sc.label } })
            };
        }).filter(c => c.subCategories.length > 0);

        const questionsDto = { questionCount: quizSize, categories: filteredCategories, filterOption: questionFilter };
        axios.post('api/questions/configure', questionsDto, { withCredentials: true }).then(response =>
        {
            setSubmitting(false);
            onFinishedConfig(response.data);
        })
        .catch((error) =>
        {
            setSubmitting(false);
            if (error.response.status === 401)
                navigate("/");
            else setAlertInfo({ type: "error", message: error?.response?.data?.message ?? "An error occured" });
        });;
    }

    function someButNotAllSubCategoriesChecked(category)
    {
        const subCategoryCheckedCount = category.subCategories.filter(sc => sc.checked).length;
        return 0 < subCategoryCheckedCount && subCategoryCheckedCount < category.subCategories.length;
    }

    if (submitting || categories === null)
        return <LoadingSpinnerCentred topMargin={true} />

    const disabledWhenNoUserStyle = (flag) => {
        return {
            opacity: user ? 1 : 0.45,
            pointerEvents: user ? "initial" : "none"
        }
    }

    return (
        <Box>
            <Container maxWidth="xs">
                <Box sx={{ marginTop: 2, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <FormControl sx={disabledWhenNoUserStyle()}>
                        <RadioGroup value={quizSize} onChange={(e) => setQuizSize(e.target.value)}>
                            {quizSizes.map((o) => (
                                <FormControlLabel key={o.value} value={o.value} control={<Radio />} label={o.label}
                                    sx={{ ml: .1, '& .MuiSvgIcon-root': { fontSize: 30 } }} />
                            ))}
                        </RadioGroup>
                    </FormControl>
                </Box>
            </Container>

            <Container maxWidth={user ? "xs" : "md"} sx={{ mt: 4 }}>

                <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' } }} alignItems="center">
                    <Container maxWidth="xs">
                        <FormControl fullWidth sx={disabledWhenNoUserStyle()}>
                            <InputLabel id="filter-type">Questions Filter</InputLabel>
                            <Select label="Questions Filter" name="filter" labelId="filter-type" value={questionFilter}
                                onChange={(e) => setQuestionFilter(e.target.value)}>
                                {filterOptions.map(ft => {
                                    return <MenuItem key={ft.value} value={ft.value}>{ft.label}</MenuItem>
                                })}
                            </Select>
                        </FormControl>
                    </Container>

                    {!user &&
                        <Box sx={{ maxWidth: "400px", display: 'flex', flexDirection: { xs: 'column', md: 'row' } }} alignItems="center">
                            <ArrowUpwardRoundedIcon sx={{ my: 2, display: { xs: 'block', md: 'none' } }} />
                            <KeyboardBackspaceRoundedIcon sx={{ mr: 3, display: { xs: 'none', md: 'block' } }}/>
                            <Box sx={{ p: 3, border: 2, borderRadius: 1, borderColor: 'primary.main' }}>
                                <Typography>{"With the full version filter questions by those you\u2018ve not answered, or that you\u2018ve answered incorrectly"}</Typography>
                            </Box>
                        </Box>
                    }
                </Box>

                <Container maxWidth="xs">
                    <Typography sx={{ mt: 2 }} component="h2" variant="subtitle1" color="primary">
                        Categories
                    </Typography>
                </Container>

                <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' } }} alignItems="center">
                    <Container maxWidth="xs">
                        <List disablePadding sx={disabledWhenNoUserStyle()}>
                            {categories.map((category, index) => (
                                <Paper key={index} id={category.id} sx={{ mt: 2 }}>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                        <ListItemButton disableGutters onClick={(e) => toggleChecked(index)} dense sx={{ '& .MuiSvgIcon-root': { fontSize: 30 } }}>
                                            <ListItemIcon className={classes.icon} >
                                                <Checkbox disableRipple checked={category.subCategories.every(sc => sc.checked)}
                                                    indeterminate={someButNotAllSubCategoriesChecked(category)} />
                                            </ListItemIcon>

                                            <ListItemText primary={category.label} fontSize={60} />
                                        </ListItemButton>
                                        <IconButton onClick={(e) => toggleOpen(e, index)} sx={{ p: 1, m: 1 }}>
                                            {category.open ? <ExpandLess /> : <ExpandMore />}
                                        </IconButton>
                                    </Box>

                                    <Collapse in={category.open}>
                                        <List component="div" disablePadding dense>
                                            {category.subCategories.map((subCat, subIndex) => (
                                                <ListItemButton onClick={(e) => toggleSubCategoryChecked(index, subIndex)}>

                                                    <ListItemIcon className={classes.icon}>
                                                        <Checkbox disableRipple checked={subCat.checked} />
                                                    </ListItemIcon>

                                                    <ListItemText primary={subCat.label} />

                                                </ListItemButton>
                                            ))}
                                        </List>
                                    </Collapse>
                                </Paper>
                            ))}
                        </List>
                    </Container>

                    {!user &&
                        <Box sx={{ maxWidth: "400px", display: 'flex', flexDirection: { xs: 'column', md: 'row' } }} alignItems="center">
                            <ArrowUpwardRoundedIcon sx={{ my: 2, display: { xs: 'block', md: 'none' } }} />
                            <KeyboardBackspaceRoundedIcon sx={{ mr: 3, display: { xs: 'none', md: 'block' } }} />
                            <Box sx={{ p: 3, border: 2, borderRadius: 1, borderColor: 'primary.main' }}>
                                <Typography>{"With the full version choose from the four main disciplines or expand to choose from many subcategories"}</Typography>
                            </Box>
                        </Box>
                    }

                </Box>
            </Container>

            <Container maxWidth="xs">
                <Box sx={{ mt: 4, mb: 4 }}>
                    {user ?
                        <Button type="submit" fullWidth variant="contained" onClick={onSubmit}>Start</Button>
                        :
                        <Grid container rowSpacing={{ xs: 1.5 }} columnSpacing={{ xs: 1.5 }}>
                            <Grid item xs={6}>
                                <Button sx={{ mt: 2 }} fullWidth variant="contained" onClick={onSubmit}>
                                    Start
                                </Button>
                            </Grid>
                            <Grid item xs={6}>
                                <Button sx={{ mt: 2 }} fullWidth variant="contained" onClick={() => navigate("/register")}>
                                    Sign-up
                                </Button>
                            </Grid>
                        </Grid>
                    }
                    
                    {alertInfo && <Alert severity={alertInfo.type} > {alertInfo.message}</Alert>}
                </Box>
            </Container>
        </Box>
    );
}