import React from "react";
import Box from '@mui/material/Box';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';

export default function RadioGroupTemplate(props) 
{
    return (
        <Box sx={{ marginTop: 4, display: 'flex', flexDirection: 'column', alignItems: 'left' }}>
            <FormControl>
                <RadioGroup value={props.selectedItem} onChange={props.onChanged}>
                    {props.children}
                </RadioGroup>
            </FormControl>
        </Box>
    );
}