import React, { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import MenuIcon from '@mui/icons-material/Menu';
import { Box, Toolbar, IconButton, Typography, Menu, Container, Button, MenuItem } from '@mui/material';
import { Link } from "react-router-dom";
import UserProfileButton from './UserProfileButton';
import FavIcon from '../Resources/favIconLarge.png';
import { useNavigate } from 'react-router-dom';

export default function ResponsiveAppBar(params)
{
    const { user, pages, onLoggedOut, darkTheme, appBarHeight } = params;
    const [anchorElNav, setAnchorElNav] = useState(null);
    const [adminMenuEl, setAdminMenuEl] = useState(null);
    const navigate = useNavigate();

    function handleCloseNavMenu()
    {
        setAnchorElNav(null);
        setAdminMenuEl(null);
    }

    const nonAdminPages = pages.filter(p => p.requires !== "admin");
    const adminPages = pages.filter(p => p.requires === "admin");
    const logoColor = darkTheme ? "secondary" : "black";

    return (
        <AppBar sx={{ height: appBarHeight }} position="static">
            <Container maxWidth="xl">
                <Toolbar disableGutters>

                   {/*Small menu*/}
                    <Box sx={{ flexGrow: 2, display: { xs: 'flex', md: 'none' } }}>
                        <IconButton size="large" aria-label="account of current user" aria-controls="menu-appbar"
                            aria-haspopup="true" onClick={(e) => setAnchorElNav(e.currentTarget)} color="inherit">
                            <MenuIcon />
                        </IconButton>
                        <Menu id="menu-appbar" anchorEl={anchorElNav} anchorOrigin={{ vertical: 'bottom', horizontal: 'left', }} keepMounted
                            transformOrigin={{ vertical: 'top', horizontal: 'left' }} open={Boolean(anchorElNav)} onClose={handleCloseNavMenu}
                            sx={{ display: { xs: 'block', md: 'none' } }}>
                            {pages.map((page, index) => (
                                <MenuItem key={index} component={Link} to={page.link} onClick={handleCloseNavMenu}>
                                    <Typography textAlign="center">{page.name}</Typography>
                                </MenuItem>
                            ))}
                        </Menu>
                    </Box>

                    {/*Title logo*/}
                    <Box sx={{ flexGrow: 2, display: 'inline-flex', cursor: "pointer", backgroundColor:"transparent", border:0 }} onClick={() => navigate("/")} >
                        <Box component="img" src={FavIcon} sx={{ height: 32, width: 32, mr: 1 }} />
                        <Box sx={{ display: 'inline-flex' }}>
                            <Typography inline variant="h6" noWrap component="div">Path</Typography>
                            <Typography inline variant="h6" noWrap component="div" color={logoColor}>Question</Typography>
                            <Typography inline variant="h6" noWrap component="div" sx={{ mr: 2 }}>Bank</Typography>
                        </Box>
                    </Box>

                    {/*Page buttons*/}
                    <Box sx={{ flexGrow: 5, display: { xs: 'none', md: 'flex' } }}>
                        {nonAdminPages.map((page, index) => (
                            <Button key={index} component={Link} to={page.link} onClick={handleCloseNavMenu} sx={{ my: 2, color: 'white', display: 'block' }}>
                                {page.name}
                            </Button>
                        ))}

                        {/*Admin menu*/}
                        {user?.isAdmin &&
                            <Box>
                                <Button onClick={(e) => setAdminMenuEl(e.currentTarget)} sx={{ my: 2, color: 'white', display: 'block' }}>
                                    Admin Menu
                                </Button>
                                <Menu anchorEl={adminMenuEl} anchorOrigin={{ vertical: 'bottom', horizontal: 'left', }} keepMounted
                                    transformOrigin={{ vertical: 'top', horizontal: 'left' }} open={Boolean(adminMenuEl)} onClose={handleCloseNavMenu}>
                                    {adminPages.map((page, index) => (
                                        <MenuItem key={index} component={Link} to={page.link} onClick={handleCloseNavMenu}>
                                            <Typography textAlign="center">{page.name}</Typography>
                                        </MenuItem>
                                    ))}
                                </Menu>
                            </Box>
                        }
                    </Box>

                    <UserProfileButton sx={{ flexGrow: 2 }} user={user} onLoggedOut={onLoggedOut} handleCloseNavMenu={handleCloseNavMenu} />
              
                </Toolbar>
            </Container>
        </AppBar>
    );
}