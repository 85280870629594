import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { Alert, CircularProgress, Container, Typography, Tooltip } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import axios from "axios";
import React, { useEffect, useState } from 'react';
import GeneralDialog from "../Components/GeneralDialog";
import { Navigate } from 'react-router-dom';
import SendIcon from '@mui/icons-material/Send';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';

export default function InstitutionUsers(params)
{
    const { user } = params;
    const [emails, setEmails] = useState(null);
    const [alertInfo, setAlertInfo] = useState(null);
    const [dialogInfo, setDialogInfo] = useState(null);
    const [sentEmails, setSentEmails] = useState([]);
    const [sendingEmail, setSendingEmail] = useState(false);

    useEffect(() =>
    {
        refreshEmailList();
    }, [])

    function refreshEmailList()
    {
        axios.get('api/institution/emails', { withCredentials: true }).then(result =>
        {
            setEmails(result.data);
        })
        .catch((error) =>
        {
            setAlertInfo({ type: "error", message: error?.response?.data?.message ?? "An error occured" });
        });
    }

    function OnShowAddEmailDialog()
    {
        const dialogInfo =
        {
            initialText: "",
            hasTextEntry: true,
            title: "Add email address",
            actions: [{ handler: (email) => onAddEmail(email), text: "Confirm" }]
        };

        setDialogInfo(dialogInfo);
    }

    function onAddEmail(email)
    {
        setEmails(null);
        setAlertInfo(null);
        setDialogInfo(null);
        axios.post('api/institution/add-email', { email: email }, { withCredentials: true }).then(result =>
        {
            refreshEmailList();
        })
        .catch((error) =>
        {
            setAlertInfo({ type: "error", message: error?.response?.data?.message ?? "An error occured" });
            refreshEmailList();
        });
    }

    function onDeleteEmail(email)
    {
        setEmails(null);
        setAlertInfo(null);
        setDialogInfo(null);
        axios.delete('api/institution/remove-email', { data: { email: email } }, { withCredentials: true }).then(result =>
        {
            refreshEmailList();
        })
        .catch((error) =>
        {
            setAlertInfo({ type: "error", message: error?.response?.data?.message ?? "An error occured" });
            refreshEmailList();
        });
    }

    function onSendEmailNotification(email)
    {
        setSendingEmail(true);
        axios.post('api/institution/send-email', { email: email }, { withCredentials: true }).then(result =>
        {
            setSentEmails(sentEmails => [...sentEmails, email]);
            setSendingEmail(false);
        })
        .catch((error) =>
        {
            setSendingEmail(false);
            setAlertInfo({ type: "error", message: error?.response?.data?.message ?? "An error occured sending email" });
        });
    }

    if (user === null)
        return <Navigate to="/" />;

    if (!emails || sendingEmail)
    {
        return (
            <Container maxWidth="sm" sx={{ mt: 6 }}>
                <CircularProgress/>
            </Container>
        );
    }

    return (
        <Container component="main" maxWidth="sm">

            <Typography textAlign="center" color="primary" component="h1" variant="h4" sx={{ mt: 8 }}>Institution Config</Typography>

            {alertInfo && <Alert severity={alertInfo.type}>{alertInfo.message}</Alert>}

            <List>
                {emails.map((item, index) =>
                {
                    const label = `${index + 1}. ${item.email}`;
                    const hasSent = sentEmails.find(e => e === item.email);

                    return (
                        <ListItem key={index} secondaryAction={
                            <Tooltip title="Remove email address">
                                <IconButton onClick={() => onDeleteEmail(item.email)} edge="end" aria-label="delete">
                                    <DeleteIcon />
                                </IconButton>
                            </Tooltip>
                        }>

                            <ListItemText primary={label} />

                            {hasSent ?
                                <Tooltip title="Email notification has been sent to this user">
                                    <ListItemIcon>
                                        <IconButton disabled>
                                            <MarkEmailReadIcon color="success" />
                                        </IconButton>
                                    </ListItemIcon>
                                </Tooltip>
                                :
                                <Tooltip title="Send email to notify access has been granted">
                                    <ListItemIcon>
                                        <IconButton onClick={() => onSendEmailNotification(item.email)}>
                                            <SendIcon color="primary"/>
                                        </IconButton>
                                    </ListItemIcon>
                                </Tooltip>
                            }

                        </ListItem>
                    );
                })}

                {emails.length < user.institutionSubCount &&
                    <ListItem disablePadding>
                        <ListItemButton onClick={OnShowAddEmailDialog}>
                            <ListItemIcon>
                                <AddIcon />
                            </ListItemIcon>
                            <ListItemText primary="Add email" />
                        </ListItemButton>
                    </ListItem>
                }
            </List>

            <Typography textAlign="right" color="success.main" component="h2" variant="h6">{emails.length}/{user.institutionSubCount} user subscriptions</Typography>

            {dialogInfo && <GeneralDialog dialogInfo={dialogInfo} setDialogInfo={setDialogInfo} />}

        </Container>
    )
}