import { Box, Button, Container, Grid, Typography } from '@mui/material';
import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import BlueGlobe from '../Resources/blueGlobe.png';
import ExplanationExample from '../Resources/explanationExample.jpg';
import FilterExample from '../Resources/filterExample.jpg';
import BackgroundImage from '../Resources/homeBackground.jpg';
import QuestionExample from '../Resources/questionExample.jpg';
import ThumbsUpPerson from '../Resources/thumbsUpPerson.png';

export default function HomePage(params)
{
    const { user, darkMode } = params;
    const navigate = useNavigate();

    const onSignupClick = useCallback(() => navigate('/register', { replace: true }), [navigate]);
    const titleBgColour = darkMode ? "#00000066" : "#FFFFFF66";

    return (

        <Box>
            <Box style={{ backgroundImage: `url(${BackgroundImage})`, backgroundSize: "cover", minHeight: "55vh", padding: 40 }}>

                <Container maxWidth="sm" sx={{ mt: { xs: 0, md: 8 } }}>

                    <Container maxWidth="xs">
                        <Box bgcolor={titleBgColour} sx={{ borderRadius: 1, pt:1, pb:1 }}>
                            <Typography textAlign="center" component="h1" variant="h4">
                                Work smart
                            </Typography>
                            <Typography sx={{ mt: .5 }} textAlign="center" component="h2" variant="h5">
                                Stay up to date
                            </Typography>
                            <Typography textAlign="center" component="h2" variant="h5">
                                Pass first time
                            </Typography>
                        </Box>
                    </Container>

                    {!user &&
                        <Grid container spacing={2} sx={{ px: { xs: 6, md: 0 } }}>
                            <Grid item xs={12} md={4} order={{ md: 1, xs: 1 }}>
                                <Button color="secondary" onClick={() => navigate("/questions")} fullWidth variant="contained"
                                    sx={{ mt: { xs: 2, md: 4 }, mr: { xs: 4, md: 0 }, padding: 0 }}>
                                    <Box>
                                        <Typography sx={{ my: { xs: 0, md: 1 } }}>Free demo</Typography>
                                        <Typography sx={{ textTransform: "none" }} variant="caption">Access 20 free questions</Typography>
                                    </Box>
                                </Button>
                            </Grid>
                            <Grid item xs={12} md={4} order={{ md: 1, xs: 1 }}>
                                <Button color="secondary" onClick={onSignupClick} fullWidth variant="contained"
                                    sx={{ mt: { xs: 0, md: 4 }, padding: 0 }}>
                                    <Box>
                                        <Typography sx={{ my: { xs:0, md: 1 } }}>Sign-up</Typography>
                                        <Typography sx={{ textTransform: "none" }} variant="caption">Serious value for money</Typography>
                                    </Box>
                                </Button>
                            </Grid>
                            <Grid item xs={12} md={4} order={{ md: 1, xs: 1 }}>
                                <Button color="secondary" onClick={() => navigate("/about")} fullWidth variant="contained"
                                    sx={{ mt: { xs: 0, md: 4 }, padding: 0 }}>
                                    <Box>
                                        <Typography sx={{ my: { xs: 0, md: 1 } }}>Find out more</Typography>
                                        <Typography sx={{ textTransform: "none" }} variant="caption">Why use this site?</Typography>
                                    </Box>
                                </Button>
                            </Grid>
                        </Grid>
                    }

                </Container>
            </Box>

            <Container maxWidth="xl">

                <Container maxWidth="md">
                    <Typography component="h3" variant="h5" textAlign="center" sx={{ mt: 4, mb: 4 }}>
                        Path Question Bank is an independent website to help you learn the fundamentals of clinical haematology.
                    </Typography>
                </Container>

                <Grid container spacing={6} sx={{ mb: 2, px: { xs: 8, md: 0 } }}>
                    <Grid item xs={12} md={4}>
                        <Box>
                            <Box component="img" src={QuestionExample} sx={{ width: "100%", border: 1 }} />
                            <Box bgcolor="secondary.main" sx={{ pl: 1, pr: 1, pb: .3, pt: .3 }}>
                                <Typography color="white" textAlign="center">
                                    Over 900 individual questions as MCQs and EMQs
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Box>
                            <Box component="img" src={FilterExample} sx={{ width: "100%", border: 1 }} />
                            <Box bgcolor="secondary.main" sx={{ pl: 1, pr: 1, pb: .3, pt: .3 }}>
                                <Typography color="white" textAlign="center">
                                    Precise question filters so you can study however you like
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Box>
                            <Box component="img" src={ExplanationExample} sx={{ width: "100%", border: 1 }} />
                            <Box bgcolor="secondary.main" sx={{ pl: 1, pr: 1, pb: .3, pt: .3 }}>
                                <Typography color="white" textAlign="center">
                                    Comprehensive explanations, figures, and references
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>

                <Typography component="h4" variant="h5" textAlign="center" sx={{ fontWeight: 'bold', mt: 2 }}>
                    Our users love the site
                </Typography>

                <Container maxWidth="sm">
                    <Box display="flex" alignItems="center" >
                        <Box component="img" src={ThumbsUpPerson} sx={{ alignSelf: 'center' }} />
                        <Box sx={{ alignSelf: 'center' }}>
                            <Typography variant="subtitle1" sx={{ fontStyle: "italic", mt: 2, mb: 2, lineHeight: "20px" }}>
                                {"\u0022This site is so useful, I don\u2018t know what I would have done without it. Thank you!\u0022 Nuno"}
                            </Typography>
                            <Typography display="block" sx={{ fontStyle: "italic", mt: 2, mb: 2, lineHeight: "20px" }}>
                                {"\u0022Somehow, I managed to pass part 1 and I can honestly say it was largely thanks to PathQuestionBank.\u0022 Rachel"}
                            </Typography>
                            <Typography display="block" sx={{ fontStyle: "italic", mt: 2, mb: 2, lineHeight: "20px" }}>
                                {"\u0022Your explanation of red cell membranopathies is the best I\u2018ve ever seen\u0022 Jamie"}
                            </Typography>
                        </Box>
                    </Box>
                </Container>

                <Box display="flex" justifyContent="center" alignItems="center" >
                    <Box component="img" src={BlueGlobe} sx={{ width: "128px", height: "128px" }} />
                </Box>

                <Box display="flex" justifyContent="center" alignItems="center" >
                    <Typography display="block" variant="caption" align="center" sx={{ maxWidth: "200px", mt: 2, mb: 2, lineHeight: "16px" }}>
                        We are signed up to Stripe Climate which means that 1% of the sign-up fee will go towards scaling carbon removal technology.
                    </Typography>
                </Box>

            </Container>
        </Box>
    );
  }