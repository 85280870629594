import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import React from "react";
import ReactMarkdown from "react-markdown";
import UnselectableStyle from "./Unselectable.css";
import rehypeRaw from 'rehype-raw';
import remarkGfm from 'remark-gfm'; // Not currently used? But pandoc has an option to output in gfm format

// This is a mix of html and markdown since markdown only seems to support very simple tables
// So the html is required mainly for the more complicated table definitions
export default function CustomMarkdown(props)
{
    const { children, id, noParagraphMargins } = props;

    function MarkdownTable(props)
    {
        return (
            <TableContainer component={Paper} sx={{ mb: 4 }}>
                <Table size="small" aria-label="a dense table">{props.children}</Table>
            </TableContainer>
        );
    }

    function MarkdownTableHeaderCell(props)
    {
        return <TableCell><Typography component="h3" variant="h6" sx={{ userSelect: "none" }}>{props.children}</Typography></TableCell>
    }

    function MarkdownTableCell(props)
    {
        return <TableCell {...props}><Typography sx={{ userSelect: "none" }}>{props.children}</Typography></TableCell>
    }

    function MarkdownTableRow(props)
    {
        return <TableRow>{props.children}</TableRow>
    }

    function MarkdownTableBody(props)
    {
        return <TableBody>{props.children}</TableBody>
    }

    function MarkdownTableHead(props)
    {
        return <TableHead {...props}>{props.children}</TableHead>
    }

    function MarkdownParagraph(props)
    {
        return <p {...props} className={CombineClasses(props, "unselectable")} />
    }

    function MarkdownParagraphNoMargins(props)
    {
        return <p {...props} className={CombineClasses(props, "unselectableNoMargins")} />
    }

    function CombineClasses(props, extraClass)
    {
        return !props.className ? extraClass : `${props.className} ${extraClass}`;
    }

    const CustomMarkdownImageRenderer =
    {
        li: props =>
        {
            // Work around for text using '\u00a0' or &nbsp; which is a "non-breaking-space"
            var child = props.children?.length === 1 ? props.children[0] : null;
            if (typeof child === 'string' || child instanceof String)
                return <li {...props} className={CombineClasses(props, "listStyle")}>{child.replaceAll('\u00a0', ' ')}</li>

            return <li {...props} className={CombineClasses(props, "listStyle")}/>
        },

        p: noParagraphMargins ? MarkdownParagraphNoMargins : MarkdownParagraph,

        img: image =>
        {
            if (!image.alt || !image.alt.includes(":"))
                return <img alt={image.alt} {...image} />

            const sizes = image.alt.split(':');
            const style = { width: sizes[0], height: sizes[1] };
            return <img alt="drawing" src={image.src} style={style} />;
        },

        a: (props) =>
        {
            if (props.children.length !== 1)
                return <a href={props.href} {...props} />

            const displayName = props.children.length === 1 ? props.children[0] : "[MarkdownParseFail]";
            return <a href={props.href} target="_blank" rel="noreferrer">{displayName}</a>;
        },

        table: MarkdownTable,
        thead: MarkdownTableHead,
        tbody: MarkdownTableBody,
        th: MarkdownTableHeaderCell,
        tr: MarkdownTableRow,
        td: MarkdownTableCell
    }

    return <ReactMarkdown id={id} escapeHtml={false} children={children}
        components={CustomMarkdownImageRenderer} rehypePlugins={[rehypeRaw]} remarkPlugins={[remarkGfm]} />
}