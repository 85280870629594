import { Box, Button } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import React, { useState } from "react";

export default function GeneralDialog(params)
{
    const { dialogInfo, setDialogInfo } = params;
    const [text, setText] = useState(dialogInfo.initialText ?? null);

    return (
        <Dialog fullWidth maxWidth="sm" open={dialogInfo !== null} onClose={(e) => setDialogInfo(null)}>
            <DialogTitle>{dialogInfo.title}</DialogTitle>
            {dialogInfo.text && <DialogContent>
                <DialogContentText>{dialogInfo.text}</DialogContentText>
            </DialogContent>}
            {dialogInfo.hasTextEntry && <Box pl={3} pr={3}>
                <TextField margin="dense" fullWidth autoFocus id="name" type="email" label="Name" variant="standard" value={text} onChange={e => setText(e.target.value)} />
            </Box>}
            <DialogActions>
                {dialogInfo?.actions?.map((action) => (
                    <Button onClick={() => action.handler(text)}>{action.text}</Button>
                ))}
            </DialogActions>
        </Dialog>
    ); 
}