import React, { useState } from "react";
import RadioResult from "./RadioResult";
import QuestionTemplate from "./QuestionTemplate";
import RadioGroupTemplate from "./RadioGroupTemplate";
import CustomMarkdown from "../Components/CustomMarkdown";
import { Stack, Typography } from '@mui/material';

export default function MultipleChoiceQuestion(props)
{
    const { user } = props;
    const [selectedAnswer, setSelectedAnswer] = useState(null);

    function onSubmitAnswer()
    {
        props.onSumbitAnswer(parseInt(selectedAnswer));
    }

    function onSelectedAnswerChanged(e)
    {
        setSelectedAnswer(e.target.value);
    }

    const { qIndex, questions, userAnswers, onSkipQuestion, onNextQuestion } = props;
    const answered = props.userAnswers.length > qIndex; // We have already answered this question
    const userAnswer = answered ? userAnswers[qIndex].optionId : null;
    const correctAnswer = answered ? userAnswers[qIndex].correctAnswerId : null;
    const onSelectedChangeHandler = answered ? null : onSelectedAnswerChanged;
    const isCorrect = userAnswer === correctAnswer;

    return (
        <QuestionTemplate index={qIndex} questions={questions} answered={answered} user={user}
            onSkipQuestion={onSkipQuestion} onSumbitAnswer={onSubmitAnswer} onNextQuestion={onNextQuestion} >
            <RadioGroupTemplate selectedItem={selectedAnswer ? selectedAnswer : " "} onChanged={onSelectedChangeHandler}>
                {questions[qIndex].options.map((o) =>
                (
                    <RadioResult key={o.id} value={o.id} label={o.text} userAnswer={userAnswer} correctAnswer={correctAnswer} />
                ))}
            </RadioGroupTemplate>

            {
                answered &&
                <Stack>
                    <Stack direction="row" spacing={1} sx={{ mt: 2, mb: 2 }}>
                        <Typography>Your answer:</Typography>
                        <Typography color={isCorrect ? "success.main" : "error.main"}>{isCorrect ? "Correct" : "Incorrect"}</Typography>
                    </Stack>

                    <CustomMarkdown children={questions[qIndex].information} />
                </Stack>
            }

        </QuestionTemplate>
    );
}