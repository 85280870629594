import { Container, Alert, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import axios from "axios";
import { useLocation } from "react-router-dom";
import CustomMarkdown from "../Components/CustomMarkdown";
import LoadingSpinnerCentred from "../Components/LoadingSpinnerCentred";

export default function ArticlePage()
{
    const { state } = useLocation();
    const [loading, setLoading] = useState(true);
    const [alertInfo, setAlertInfo] = useState(null);
    const [article, setArticle] = useState(state?.article);

    const urlParams = new URLSearchParams(window.location.search);
    const articleTitle = urlParams.get("title");

    function fetchArticle()
    {
        if (!articleTitle)
        {
            setLoading(false);
            return;
        }

        setLoading(true);
        axios.post('api/articles/find-article', { title: articleTitle }, { withCredentials: true }).then(response =>
        {
            setArticle(response.data);
            setLoading(false);
        })
        .catch(error =>
        {
            setAlertInfo({ type: "error", message: error?.response?.data?.message ?? "An error occured" });
            setLoading(false);
        });
    }

    useEffect(() => fetchArticle(), []);

    if (loading)
        return <LoadingSpinnerCentred marginTop={true} />

    if (alertInfo)
    {
        return (
            <Container maxWidth="sm" sx={{ mt: 8, alignItems: 'centre' }}>
                <Alert severity={alertInfo.type}> {alertInfo.message}</Alert>
            </Container>
        );
    }

    if (!article)
        return <Typography sx={{ marginTop: 2 }} align="center" component="h1" variant="h6" gutterBottom>No article found</Typography>;

    return (
        <Container maxWidth="md" sx={{ alignItems: 'centre' }}>
            <Typography sx={{ marginTop: 8 }} align="center" component="h1" variant="h4" gutterBottom color="primary">
                {article.title}
            </Typography>

            <Typography sx={{ marginTop: 2 }} align="center" component="h1" variant="h6" gutterBottom>
                {article.subTitle}
            </Typography>

            <CustomMarkdown children={article.body}/>
                
        </Container>
    );
}