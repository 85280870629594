import React from "react";
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { Box, List, ListItem, IconButton, TextField, Stack, InputLabel, Typography } from '@mui/material';
import LetterIndexTextEntry from "./LetterIndexTextEntry";

function IndexToLetter(index) {
    return `${String.fromCharCode(65 + index)}:`;
}

export default function AddRemoveList(props)
{
    const { title, items, onAdd, onChange, onDelete, displayLetterHeading, onAnswerChanged, maxAnswerIndex } = props;

    return (

        <Stack sx={{ mt: 4 }}>
            <InputLabel>{title}</InputLabel>
            <Box sx={{ border: 1, borderRadius: '4px' }}>
                <List>
                    {items.map(function (item, index)
                    {
                        return (
                            <ListItem key={index}>
                                {
                                    displayLetterHeading && <Typography sx={{ mr: 2, fontWeight: 'bold' }}>{IndexToLetter(index)}</Typography>
                                }
                                <TextField id="option-name" value={item.text} variant="standard"
                                    maxRows={4} multiline fullWidth hiddenLabel required
                                    onChange={(event) => onChange(event, index)} sx={{ mr: 2 }}/>
                                {
                                    onAnswerChanged &&
                                    <LetterIndexTextEntry index={index} currentValue={item.answerIndex} id={item.id}
                                        maxAnswerIndex={maxAnswerIndex} onValueChanged={onAnswerChanged} />
                                }
                                <IconButton edge="end" aria-label="delete" onClick={(e) => onDelete(index)} >
                                    <DeleteIcon />
                                </IconButton>
                            </ListItem>
                        );
                    })}
                    <ListItem>
                        <IconButton onClick={onAdd} edge="end" aria-label="delete">
                            <AddIcon />
                        </IconButton>
                    </ListItem>
                </List>
            </Box>
        </Stack>
    );
}