import { Box, Button, Container, Grid, Typography } from "@mui/material";
import CustomMarkdown from "../Components/CustomMarkdown";
import React from 'react';

export default function QuestionTemplate(params)
{
    const { index, user, questions, onNextQuestion, onSkipQuestion, onSumbitAnswer, answered, children } = params;
    const feedbackUrl = `mailto:info@pathquestionbank.com?subject=Feedback on ${questions[index].name}`;
    const isLastQuestion = index >= questions.length - 1;

    return (
        <Container component="main" maxWidth="md" sx={{ mt:4 }}>

            <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%' }} >
                <Typography align="left" component="h2" variant="h5" gutterBottom color="primary">
                    Question {index + 1} of {questions.length}
                </Typography>
                {user?.isAdmin && <Typography sx={{ ml: 2 }} component="h2" variant="h6" gutterBottom color="primary">(Admin Info: {questions[index].name})</Typography>}
            </Box>

            <Box sx={{ mt: 4, mb: 4 }} >
                <CustomMarkdown children={questions[index].text} />
            </Box>

            {children}

            {answered ?
                <Button sx={{ mt: 4, mb: 4 }} fullWidth variant="contained" onClick={onNextQuestion}>
                    {isLastQuestion ? "Finish Test" : "Next Question"}
                </Button>
                :
                <Grid container sx={{ mt: 4, mb: 4 }} columnSpacing={{ xs: 4 }}>
                    <Grid item xs={6}>
                        <Button type="submit" fullWidth variant="contained" onClick={onSumbitAnswer}>Submit Answer</Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button fullWidth variant="outlined" onClick={onSkipQuestion}>Skip</Button>
                    </Grid>
                </Grid>
            }

            <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', mt: 4, mb: 4 }} >
                <Typography align="center" variant="caption">
                    Think you can improve this question?
                </Typography>
                <Typography align='center'>
                    <Button component="a" href={feedbackUrl} color='primary' size='large'>Give Feedback</Button>
                </Typography>
            </Box>

        </Container>
    );
}