import { Typography } from '@material-ui/core';
import { Alert, Box } from '@mui/material';
import axios from "axios";
import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import LoadingSpinnerCentred from "../Components/LoadingSpinnerCentred";

export default function VerifyPage(params)
{
    const onLoggedIn = params.onLoggedIn;
    const [alertInfo, setAlertInfo] = useState(null);
    let navigate = useNavigate();

    useEffect(() =>
    {
        const urlParams = new URLSearchParams(window.location.search);
        const urlRef = urlParams.get("_url_ref");
        const productId = urlParams.get("_product");

        axios.post('api/users/verify', { urlRef: urlRef }).then(response =>
        {
            onLoggedIn(response.data);

            const alertInfo = { type: "success", message: "Your account is now verified" };
            if (productId)
                navigate("/payment", { state: { productId: productId, alertInfo: alertInfo } })
            else navigate("/profile", { state: { alertInfo: alertInfo } })
        })
        .catch((error) =>
        {
            setAlertInfo({ type: "error", message: error?.response?.data?.message ?? "An error occured" });
        });
    }, [onLoggedIn]);

    if (alertInfo)
    {
        return (
            <Box sx={{ marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Alert severity={alertInfo.type}>{alertInfo.message}</Alert>
            </Box>
        );
    }
    
    return (
        <Box sx={{ marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Typography variant="h5">Verifying...</Typography>
            <LoadingSpinnerCentred topMargin={true}/>
        </Box>
    );  
}