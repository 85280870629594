import { Box, Container, Typography } from '@mui/material';
import React from 'react';

export default function PrivacyPolicy(props) {

    return (
        <Container maxWidth="md" sx={{ mt: 8, mb: 6 }}>

            <Typography color="primary" textAlign="center" component="h1" variant="h4" sx={{ fontWeight: 'bold' }}>
                Privacy Policy
            </Typography>

            <Typography  sx={{ mt: 2 }}>
                At www.pathquestionbank.com, we take your privacy and trust very seriously. This privacy and cookies policy ("Privacy Policy") is intended to outline how any personal data collected from you via www.pathquestionbank.com (the "Website") will be protected, used, stored and processed by us to communicate with you and to deliver you excellent service.
            </Typography>

            <Typography sx={{ mt: 2 }}>
                Your privacy is at our heart and we see this as fundamental to having a trusting and intimate relationship with each of our clients.
            </Typography>

            <Typography sx={{ fontStyle: 'italic', mt: 2 }}>
                What information do we collect from you?
            </Typography>

            <Typography sx={{ mt: 2 }}>
                www.pathquestionbank.com collects and stores the following data from you - Your name, email address. To aid the user experience we also collect data on the questions you answer and how you answer them. We use this information to tailor your learning experience and aid development of our product.
            </Typography>

            <Typography sx={{ fontStyle: 'italic', mt: 2 }}>
                What methods do we use to collect your information? 
            </Typography>

            <Typography sx={{ mt: 2 }}>
                We use the information you provide when registering for our site. www.pathquestionbank.com only uses cookies that are essential for the working of basic functionalities of the website.
            </Typography>

            <Typography sx={{ fontStyle: 'italic', mt: 2 }}>
                For what purpose do we collect this information off you?
            </Typography>

            <Typography sx={{ mt: 2 }}>
                www.pathquestionbank.com collects your data solely in order to help aid in the service given to you, the user. We will not pass your information onto any third parties.
            </Typography>

            <Typography sx={{ fontStyle: 'italic', mt: 2 }}>
                How do we keep your information safe?
            </Typography>

            <Typography sx={{ mt: 2 }}>
                We store your data including email address on a secure server. We will also use your email address with third party email services. You can opt out of receiving any emails from us by emailing us at info@pathquestionbank.com
            </Typography>

        </Container>
    );
}