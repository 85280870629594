import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LockIcon from '@mui/icons-material/Lock';
import { Alert, Box, Button, Container, Typography } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import axios from "axios";
import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import LoadingSpinnerCentred from "../Components/LoadingSpinnerCentred";
import AvailableProducts from "../Payment/AvailableProducts";
import { ToChunks } from "../Utils/HelperFunc";

export default function ProductsPage(params)
{
    const { user } = params;
    const [productId, setProductId] = useState(null);
    const [products, setProducts] = useState(null);
    const [alertInfo, setAlertInfo] = useState(null);
    useEffect(() => fetchProducts(), []);
    let navigate = useNavigate();

    function fetchProducts()
    {
        axios.get('api/payments/products').then(result =>
        {
            setProducts(result.data);
        }).catch(error =>
        {
            setAlertInfo({ type: "error", message: "Failed to acquire available subscription information" });
        });;
    };

    function handleCheckout()
    {
        if (!user)
        {
            navigate('/register');
            return;
        }
        navigate('/payment', { state: { productId: productId, isSubscription: isSubscription } })
    }

    if (alertInfo)
        return <Alert severity={alertInfo.type}> {alertInfo.message}</Alert>

    if (products === null)
        return <LoadingSpinnerCentred topMargin={true} />

    const userProducts = products.filter(p => !p.isInstitutionPackage);
    const institutionProducts = products.filter(p => p.isInstitutionPackage);
    const userProductChunks = ToChunks(userProducts, 2);
    const institutionProductsChunks = ToChunks(institutionProducts, 2);
    const isSubscription = productId && products.find(p => p.id === productId).isInstitutionPackage;

    return (
        <Box>
            <Box sx={{ flexGrow: 2, flexDirection: 'column', display: { xs: 'flex', md: 'none' } }}>
                <Container maxWidth="xs" sx={{ mt: 8, alignItems: 'centre' }}>
                    <Accordion defaultExpanded={true}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                            <Typography textAlign="center" color="primary" component="h1" variant="h6">
                                User Subscriptions
                            </Typography>
                        </AccordionSummary>
                        <AvailableProducts orientation="vertical" productId={productId} setProductId={setProductId} products={userProducts} />
                    </Accordion>
                </Container>

                <Container maxWidth="xs" sx={{ mt: 4, alignItems: 'centre' }}>
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                            <Typography textAlign="center" color="primary" component="h1" variant="h6">
                                Institution Subscriptions
                            </Typography>
                        </AccordionSummary>
                        <Typography component="h6" variant="subtitle" sx={{ ml: 2, mb: 2 }}>12 Month subscriptions with auto-renewal</Typography>
                        <AvailableProducts orientation="vertical" productId={productId} setProductId={setProductId} products={institutionProducts} />
                        <Typography component="h6" variant="subtitle" sx={{ m: 2 }}>Need a different package? Contact us at info@pathquestionbank.com with your requirements.</Typography>
                    </Accordion>
                </Container>
            </Box>

            <Box sx={{ flexGrow: 2, flexDirection: 'column', display: { xs: 'none', md: 'flex' } }}>
                <Container maxWidth="md" sx={{ mt: 8, alignItems: 'centre' }}>
                    <Accordion defaultExpanded={true}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                            <Typography textAlign="center" color="primary" component="h1" variant="h6">
                                User Subscriptions
                            </Typography>
                        </AccordionSummary>
                        {userProductChunks.map(chunk => (
                            <AvailableProducts productId={productId} setProductId={setProductId} products={chunk} />
                        ))}
                    </Accordion>
                </Container>

                <Container maxWidth="md" sx={{ mt: 4, alignItems: 'centre' }}>
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                            <Typography textAlign="center" color="primary" component="h1" variant="h6">
                                Institution Subscriptions
                            </Typography>
                        </AccordionSummary>
                        <Typography component="h6" variant="subtitle" sx={{ ml: 2, mb: 2 }}>12 Month subscriptions with auto-renewal</Typography>
                        {institutionProductsChunks.map(chunk => (
                            <AvailableProducts productId={productId} setProductId={setProductId} products={chunk} />
                        ))}
                        <Typography component="h6" variant="subtitle" sx={{ m: 2 }}>Need a different package? Contact us at info@pathquestionbank.com with your requirements.</Typography>
                    </Accordion>
                </Container>
            </Box>

            <Container maxWidth="xs" sx={{ mt: 4, mb: 4, alignItems: 'centre' }}>
                <Button disabled={!productId} onClick={handleCheckout}
                    fullWidth variant="contained" sx={{ mt: 2 }} startIcon={<LockIcon />}>
                    Checkout
                </Button>
            </Container>
        </Box>
    );
}