import { Alert, Box, Button, Container, Paper, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@mui/material';
import axios from "axios";
import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import LoadingSpinnerCentred from "../Components/LoadingSpinnerCentred";

// TODO - deduplicate, also in HistoryPage.js QuizConfig.js
const _sameQuizRetakeOption = 0;
const _differentQuestionsRetakeOption = 1;

export default function QuizResultDetails()
{
    const [quizResult, setQuizResult] = useState(null);
    const [alertInfo, setAlertInfo] = useState(null);
    const { state } = useLocation();
    let navigate = useNavigate();

    useEffect(() =>
    {
        setQuizResult(null);
        axios.get('api/answers/quiz-details/' + state.quizResultId, { withCredentials: true }).then(res =>
        {
            setQuizResult(res.data);
        })
        .catch(error =>
        {
            setAlertInfo({ type: "error", message: error?.response?.data?.message ?? "An error occured" });
        });
    }, [state]);

    function onQuestionSelected(question)
    {
        navigate("../quiz-question-details", { state: { question: question } });
    }

    function onRetakeQuiz(option)
    {
        const retakeDto = { quizResultId: quizResult.id, retakeOption: option };
        setQuizResult(null);
        axios.post('api/questions/retake', retakeDto, { withCredentials: true }).then(response =>
        {
            navigate("../questions", { state: { quizData: response.data } });
        })
        .catch(error =>
        {
            setAlertInfo({ type: "error", message: error?.response?.data?.message ?? "An error occured" });
        });
    }

    if (alertInfo)
    {
        return (
            <Container maxWidth="sm" sx={{ alignItems: 'centre' }}>
                <Alert sx={{ mt: 8 }} severity={alertInfo.type}> {alertInfo.message}</Alert >
            </Container>
        );
    }

    if (!quizResult)
        return <LoadingSpinnerCentred topMargin={true} />

    // Style for clamping to two lines and then showing '...'
    const textStyle = {
        maxWidth: '100%',
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: 2,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        userSelect: "none"
    };

    return (
        
        <Container maxWidth="lg" sx={{ alignItems: 'left' }}>

            <Typography sx={{ marginTop: 8 }} component="h1" variant="h4" gutterBottom color="primary">
                Details
            </Typography>
            <Typography sx={{ mt: 2 }}>
                Correct Answers: {Math.round(100.0 * quizResult.marks / quizResult.maxMarks)}% ({quizResult.marks}/{quizResult.maxMarks})
            </Typography>
            <Typography>
                Primary Categories: {quizResult.primaryCategories}
            </Typography>
            <Typography>
                Date time: {format(new Date(quizResult.timeStamp), "MMMM do, yyyy (HH:mm)")}
            </Typography>
            <Typography>
                Weak categories: {quizResult.weakCategories}
            </Typography>

            <Box sx={{ mt: 1.5, display: 'flex', flexDirection: 'row', width: '100%' }} >
                <Button sx={{ mr: 1.5 }} variant="contained" onClick={(e) => onRetakeQuiz(_sameQuizRetakeOption)}>
                    Retake With Same Questions
                </Button>
                <Button  variant="contained" onClick={(e) => onRetakeQuiz(_differentQuestionsRetakeOption)}>
                    Retake With Different Questions
                </Button>
            </Box>

            <Typography sx={{ mt: 2, mb: 1 }} variant="h6" color="primary.main">
                Questions
            </Typography>
            <TableContainer sx={{ mb: 4 }} component={Paper}>
                <Table>
                    <TableBody>
                        {quizResult.questions.map((question, index) =>
                        {
                            const marks = question.questionAnswers.filter(a => a.isCorrect).length;
                            const markStr = `${marks}/${question.questionAnswers.length}`;
                            const markStrColour = marks === question.questionAnswers.length ? "success.main" : "error.main";

                            return (
                                <TableRow key={index} hover onClick={(event) => onQuestionSelected(question)} tabIndex={-1}>
                                    <TableCell>
                                        <Typography sx={{ userSelect: "none" }}>{`${index + 1}.`}</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography style={textStyle}>{question.text}</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography sx={{ userSelect: "none" }} color={markStrColour}>{markStr}</Typography>
                                    </TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>

        </Container>
    );
}