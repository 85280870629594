import React from "react";
import { Typography, Divider, Grid, Stack } from "@mui/material";
import QuestionTemplate from "./QuestionTemplate";
import LetterIndexTextEntry from "../Components/LetterIndexTextEntry";
import CustomMarkdown from "../Components/CustomMarkdown";

export default function MatchingQuestion(params)
{
    const { qIndex, user, questions, answers, setAnswers, userAnswers, onSkipQuestion, onNextQuestion, onSumbitAnswer } = params;

    const question = questions[qIndex];
    const answered = userAnswers.length > qIndex; // We have already answered this question

    function getOptionLabel(option, index)
    {
        return `${String.fromCharCode(65 + index)}. ${option.text}`
    }

    function onAnswerChanged(subQuestionId, optionIndex)
    {
        const newAnswers = answers;
        const existingAnswer = newAnswers.find(a => a.subQuestionId === subQuestionId);
        if (existingAnswer)
            newAnswers.splice(newAnswers.indexOf(existingAnswer), 1);

        const option = question.options[optionIndex];
        if (option)
            newAnswers.push({ subQuestionId: subQuestionId, optionId: option.id });

        setAnswers([...newAnswers]);
    }

    function getOptionIndexForSubQuestion(subQ)
    {
        const answer = answers.find(a => a.subQuestionId === subQ.id);
        const option = answer !== undefined ? question.options.find(o => o.id === answer.optionId) : null;
        return question.options.indexOf(option);
    }

    function isAnsweredCorrectly(subQuestion)
    {
        const answer = answered ? answers.find(a => a.subQuestionId === subQuestion.id) : undefined;
        return answer && answer.optionId === answer.correctAnswer;
    }

    function getAnswerResultText()
    {
        const correctAnswers = answers.filter(a => a.optionId === a.correctAnswer);
        return `${correctAnswers.length}/${answers.length} correct`;
    }

    function getAnswerResultTextColour()
    {
        const correctAnswers = answers.filter(a => a.optionId === a.correctAnswer);
        return correctAnswers.length === answers.length ? "success.main" : "error.main";
    }

    return (
        <QuestionTemplate index={qIndex} questions={questions} answered={answered} user={user} 
            onSkipQuestion={onSkipQuestion} onNextQuestion={onNextQuestion} onSumbitAnswer={onSumbitAnswer}>

            {question.options.map((o, index) =>
            (
                <CustomMarkdown key={o.id} children={getOptionLabel(o, index)} />
            ))}

            <Divider sx={{ mt:2 }}/>

            {question.subQuestions.map((subQ, index) =>
            (
                <Grid key={subQ.id} container spacing={4} sx={{ mt:4 }}>
                    <Grid item xs={10}>
                        <CustomMarkdown children={subQ.text} noParagraphMargins={true} />
                    </Grid>
                    <Grid item xs={2}>
                        <LetterIndexTextEntry id={subQ.id} index={index} currentValue={getOptionIndexForSubQuestion(subQ)}
                            maxAnswerIndex={question.options.length} onValueChanged={onAnswerChanged}
                            answered={answered} correct={isAnsweredCorrectly(subQ)} focusNext />
                    </Grid>
                </Grid>
            ))}

            {
                answered &&
                <Stack>
                    <Stack direction="row" spacing={1} sx={{ mt: 2, mb: 2 }}>
                        <Typography>You answered:</Typography>
                        <Typography color={getAnswerResultTextColour()}>{getAnswerResultText()}</Typography>
                    </Stack>

                    <CustomMarkdown children={questions[qIndex].information} />
                </Stack>
            }

        </QuestionTemplate>
    );
    
}