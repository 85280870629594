import axios from 'axios'

const AxiosInterceptorsSetup = (navigate, logOut) =>
{
    axios.interceptors.response.use(
        response => response,
        error =>
        {
            if (error.response.status === 401)
            {
                logOut();
                navigate('/login', { state: { errorMsg: "You have been logged out, please login again." } } );
            }
            return Promise.reject(error);
        }
    );
};

export default AxiosInterceptorsSetup;