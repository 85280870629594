import { Box, Button, Container, Alert } from '@mui/material';
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import React, { useState } from 'react';
import LoadingSpinnerCentred from "../Components/LoadingSpinnerCentred";

export default function PaymentForm(params)
{
    const stripe = useStripe();
    const elements = useElements();
    const [submitting, setSubmitting] = useState(false);
    const [alertInfo, setAlertInfo] = useState(null);

    if (!stripe || !elements) {
        // Stripe.js has not loaded yet. Make sure to disable
        // form submission until Stripe.js has loaded.
        return <LoadingSpinnerCentred topMargin={true} />;
    }

    const handleSubmit = async (e) =>
    {
        e.preventDefault();
        if (!stripe || !elements)
            return;

        // TODO error logging and redirect on success, back to user profile I guess with updated account status?
        setAlertInfo(null);
        setSubmitting(true);
        const { error } = await stripe.confirmPayment(
        {
            elements,
            confirmParams: { return_url: window.location.href + "/success" }
        });

        if (error?.message)
        {
            setAlertInfo({ type: "error", message: error?.message ?? "An error occured" });
        }

        setSubmitting(false);
    }

    return (
        <Container component="main" maxWidth="sm" sx={{ mt: 8 }}>
            <Box onSubmit={handleSubmit} id="payment-form" component="form" sx={{ marginTop: 8,  flexDirection: 'column', alignItems: 'center' }}>

                <PaymentElement id="payment-element" />

                { submitting ?
                    <LoadingSpinnerCentred topMargin={true}/>
                    :
                    <Button type="submit" fullWidth variant="contained" sx={{ mt: 4, mb: 2 }}>Complete Payment</Button>
                }

                {alertInfo && <Alert severity={alertInfo.type}>{alertInfo.message}</Alert>}

            </Box>
        </Container>
    );
  }