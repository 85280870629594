import { Box, Button, Container, Typography } from '@mui/material';
import * as React from 'react';
import { Link } from "react-router-dom";
import FavIcon from '../Resources/favIconLarge.png';

export default function BottomAppBar(params) {

    const { pages, appBarHeight, darkTheme } = params;
    const logoColor = darkTheme ? "secondary" : "black";
    const background = darkTheme ? "#272727" : "primary.main";

    return (
        <Box bgcolor={background} sx={{ height: appBarHeight, display:"flex" }} >
            <Container maxWidth="sm" color="primary">

                {/*Title logo*/}
                <Box justifyContent="center" alignItems="center" display="flex" >
                    <Box component="img" src={FavIcon} sx={{ height: 24, width: 24, mr: 1, mt: 2 }} />
                    <Box sx={{ display: 'inline-flex', mt: 2 }}>
                        <Typography inline variant="h7" noWrap component="div" color="text.primary">Path</Typography>
                        <Typography inline variant="h7" noWrap component="div" color={logoColor}>Question</Typography>
                        <Typography inline variant="h7" noWrap component="div" sx={{ mr: 2 }}>Bank</Typography>
                    </Box>
                </Box>

                <Box justifyContent="center" alignItems="center" display="flex">
                    {pages.map((page, index) => (
                        <Button key={index} component={Link} to={page.link} sx={{ color: 'white', display: 'block' }}>
                            <Typography variant="caption">{page.name}</Typography>
                        </Button>
                    ))}
                </Box>

            </Container>
        </Box>
    );
}