import LockResetIcon from '@mui/icons-material/LockReset';
import { Alert, Avatar, Box, Button, CircularProgress, Container, TextField } from "@mui/material";
import axios from "axios";
import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";

export default function ResetPasswordPage(params)
{
    const [inProgress, setInProgress] = useState(false);
    const [alertInfo, setAlertInfo] = useState(null);
    const [password, setPassword] = useState(null);
    const [confirmPassword, setConfirmPassword] = useState(null);
    const urlParams = new URLSearchParams(window.location.search);
    const navigate = useNavigate();

    function handleSubmit(event)
    {
        event.preventDefault();
        if (password !== confirmPassword)
        {
            setAlertInfo({ type: "error", message: "Passwords do not match" });
            return;
        }

        setInProgress(true);
        setAlertInfo(null);
        const passwordResetDto = { urlRef: urlParams.get("_url_ref"), password: password }

        axios.post('api/users/password-reset', passwordResetDto).then(response =>
        {
            setAlertInfo({ type: "success", message: "Successfully changed password" });
            setInProgress(false);
        })
        .catch((error) =>
        {
            setAlertInfo({ type: "error", message: error?.response?.data?.message ?? "An error occured" });
            setInProgress(false);
        });
    }

    const success = alertInfo?.type === "success";
    const showLoginButton = success && !params.isLoggedIn;
    const textFieldInputProps = alertInfo ? { readOnly: true } : {};

    return (
        <Container component="main" maxWidth="xs">
            <Box sx={{ marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                    <LockResetIcon />
                </Avatar>
            </Box>

            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mb: 2 }}>

                <TextField id="password" name="password" autoComplete="current-password" label="Password" autoFocus
                    margin="normal" type="password" required fullWidth onChange={(e) => setPassword(e.target.value)}
                    inputProps={textFieldInputProps} />

                <TextField id="confirm-password" name="confirm-password" autoComplete="current-password" label="Confirm Password"
                    margin="normal" type="password" required fullWidth onChange={(e) => setConfirmPassword(e.target.value)}
                    inputProps={textFieldInputProps} />

                {showLoginButton &&
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 2 }}>
                        <Button onClick={() => navigate("/login")} fullWidth variant="contained" sx={{ mb: 2 }}>
                            Login
                        </Button>
                    </Box>
                }

                {inProgress &&
                    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                        <CircularProgress />
                    </Box>
                }

                {!inProgress && !success &&
                    <Button type="submit" fullWidth variant="contained" sx={{ mt: 2, mb: 2 }}>
                        Reset Password
                    </Button>
                }

                {alertInfo && <Alert severity={alertInfo.type}>{alertInfo.message}</Alert>}

            </Box>

        </Container>
    );  
}