import { Box, Button, Typography } from '@mui/material';
import { format } from "date-fns";
import React from 'react';
import axios from "axios";
import { useNavigate } from 'react-router-dom';

export default function SubscriptionInfo(props)
{
    const { user, setUser, setBusy, setAlertInfo, setDialogInfo } = props;
    const navigate = useNavigate();

    function getExpiryTimeStr(subExpiry)
    {
        if (!subExpiry)
            return "Never";
        return format(new Date(user.subscriptionExpires), "MMMM do, yyyy");
    }

    function onSubscriptionCancellationConfirmation()
    {
        const dialogInfo =
        {
            title: "Cancel subscription",
            text: `Are you sure you want to cancel your subscription?`,
            actions: [{ handler: onCancelSubscription, text: "Cancel" }]
        };

        setDialogInfo(dialogInfo);
    }

    function onCancelSubscription()
    {
        setDialogInfo(null);
        setBusy(true);

        axios.post('api/payments/cancel-subscription', { withCredentials: true }).then(response =>
        {
            setUser(response.data);
            setAlertInfo({ type: "success", message: "Subscription cancelled" });
            setBusy(false);
        })
        .catch(error =>
        {
            setAlertInfo({ type: "error", message: error?.response?.data?.message ?? "Failed to cancel subscription" });
            setBusy(false);
        });
    }

    if (user.isAdmin)
    {
        return (
            <Box>
                <Typography sx={{ mt: 1, fontWeight: 'bold' }} variant="subtitle1">With access to:</Typography>
                <Typography>{`All the cosmic forces of the universe`}</Typography>
            </Box>
        );
    }

    if (user.account === 1 || user.account === 2) // Full Access
    {
        return (
            <Box>
                <Box component="div" sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                    <Typography sx={{ mt: 1, mr: 1 }} inline component="h4" variant="h6" color="primary">Expiry/Renew Date:</Typography>
                    <Typography sx={{ mt: 1 }} inline component="h4" variant="h6">{getExpiryTimeStr(user.subscriptionExpires)}</Typography>
                </Box>

                <Typography sx={{ mt: 1, fontWeight: 'bold' }} variant="subtitle1">With access to:</Typography>
                <Typography>{`\u2022 All revision material`}</Typography>
                <Typography>{`\u2022 Unlimited access to the question bank and knowledge evalution system`}</Typography>

                {user.account === 2 &&
                    <Box>
                        <Button onClick={() => navigate('/institution-users')} fullWidth variant="contained" sx={{ mt: 4.2 }}>
                            Institution User Config
                        </Button>
                        <Button color="error" fullWidth variant="outlined" sx={{ mt: 2 }} onClick={onSubscriptionCancellationConfirmation}>
                            Cancel subscription
                        </Button>
                    </Box>
                }
                
            </Box>
        );
    }

    return (
        <Box>
            <Typography sx={{ mt: 1, fontWeight: 'bold' }} variant="subtitle1">With access to:</Typography>
            <Typography>{`\u2022 Common revision material`}</Typography>
            <Typography>{`\u2022 Limited use of the question bank and knowledge evalution system - coming soon!`}</Typography>

            <Typography sx={{ mt: 3.8, mb: 1, fontWeight: 'bold' }} variant="subtitle1">Purchase a subscription to gain full access</Typography>

            <Button onClick={() => navigate('/products')} fullWidth variant="contained" sx={{ mt: 1 }}>Subscriptions</Button>
        </Box>
    );
}