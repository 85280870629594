import React, { useState, useEffect } from 'react';
import axios from "axios";
import EnhancedTable from "./EnhancedTable"
import LoadingSpinnerCentred from "../Components/LoadingSpinnerCentred";
import { getAccountLabel } from "../Utils/DataLabels";
import { useNavigate } from "react-router-dom";

const userHeadCells =
[
    {
        id: 'name',
        numeric: false,
        disablePadding: true,
        label: 'Name',
    },
    {
        id: 'email',
        numeric: false,
        disablePadding: false,
        label: 'Email',
    },
    {
        id: 'account',
        numeric: false,
        disablePadding: false,
        label: 'Account Type',
    }
]

export default function UserPage()
{
    const [users, setUsers] = useState(null);
    const [page, setPage] = useState(0);
    let navigate = useNavigate();

    function fetchUsers()
    {
        setUsers(null);

        axios.get('api/users', { withCredentials: true }).then(result =>
        {
            const data = result.data.map((u) =>
            ({
                id: u.id,
                name: u.name,
                email: u.email,
                accountOverride: u.accountOverride,
                columnData: [u.name, u.email, getAccountLabel(u)],
            }));

            setUsers(data);
        });
    };

    function onAdd()
    {
        axios.post('api/users/new', { withCredentials: true }).then(result =>
        {
            navigate("../users-management-edit", { state: { userData: result.data } });
        });
    }

    function onDelete(users)
    {
        const deleteUsersDto = { Ids: users.map(u => u.id) };
        axios.delete('api/users/delete-list', { data: deleteUsersDto }, { withCredentials: true }).then(result =>
        {
            fetchUsers();
        });
    }

    function onEdit(user)
    {
        navigate("../users-management-edit", { state: { userData: user } });
    }

    useEffect(() => fetchUsers(), []);

    if (users === null)
        return <LoadingSpinnerCentred topMargin={true}/>

    return <EnhancedTable title="Users" rows={users} columns={userHeadCells} onAdd={onAdd}
        page={page} setPage={setPage} onEdit={onEdit} onDelete={onDelete} />
}