import React from "react";
import { TextField, } from '@mui/material';

function IndexToLetter(index, maxIndex)
{
    if (0 <= index && index < maxIndex)
        return `${String.fromCharCode(65 + index)}`;
    return '';
}

// TODO - use red and green from the theme colour pallet
const textStyle = { maxLength: 1, style: { width: 35, textTransform: "uppercase", textAlign: "center", fontSize: 18, fontWeight: "bold" } };
const correctStyle = { readOnly: true, maxLength: 1, style: { color: "green", width: 35, textTransform: "uppercase", textAlign: "center", fontSize: 18, fontWeight: "bold" } };
const incorrectStyle = { readOnly: true, maxLength: 1, style: { color: "red", width: 35, textTransform: "uppercase", textAlign: "center", fontSize: 18, fontWeight: "bold" } };

export default function LetterIndexTextEntry(props)
{
    const { index, id, currentValue, maxAnswerIndex, focusNext, answered, correct, onValueChanged } = props;
    const letter = IndexToLetter(currentValue, maxAnswerIndex);

    function onLetterChanged(event)
    {
        if (answered)
            return;

        const value = event.target.value;
        if (!value || value === '')
            return;

        const charCode = value.charCodeAt(0);
        const upperCase = 65 <= charCode && charCode <= 90;
        const lowerCase = 97 <= charCode && charCode <= 122;
        if (!upperCase && !lowerCase)
            return;

        const answerIndex = upperCase ? parseInt(charCode) - 65 : parseInt(charCode) - 97;
        const valid = 0 <= answerIndex && answerIndex < maxAnswerIndex;

        if (!valid && answerIndex != null)
            return;

        onValueChanged(id, answerIndex);

        if (focusNext) // Move to the next LetterIndexTextEntry
        {
            const nextfield = document.querySelector(`input[name=TextField-${valid ? index + 1 : index}]`);
            if (nextfield !== null)
                nextfield.focus();
        }
    }

    return (
        <TextField name={"TextField-" + index} variant="outlined" value={letter}
            onChange={onLetterChanged} label=" " InputLabelProps={{ shrink: false }}
            inputProps={answered ? (correct ? correctStyle : incorrectStyle) : textStyle}/>
    );
}