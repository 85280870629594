import AccountCircle from '@mui/icons-material/AccountCircle';
import { MenuItem } from '@mui/material';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import axios from "axios";
import React, { useState } from 'react';
import { Link, useNavigate } from "react-router-dom";

export default function UserProfileButton(params)
{
    const { user, onLoggedOut, handleCloseNavMenu } = params;
    const [anchorElUser, setAnchorElUser] = useState(null);
    let navigate = useNavigate();

    function handleOpenUserMenu(event)  
    {
        setAnchorElUser(event.currentTarget);
    }
    
    function handleCloseUserMenu()
    {
        setAnchorElUser(null);
    }

    function onLogout()  
    {
        handleCloseUserMenu();
        axios.delete('api/users/logout', { withCredentials: true }).then(response =>
        {
            onLoggedOut();
            navigate("/");
        })
        .catch(error =>
        {
            onLoggedOut();
            navigate("/");
        });
    }

    if (user === null) {
        return (
            <MenuItem component={Link} to="/login" onClick={handleCloseNavMenu}>
                <Typography textAlign="center">Login</Typography>
            </MenuItem>
        );
    }

    return (
        <Box>
            <Typography component='subtitle1' variant='subtitle1' color="white" noWrap
                sx={{ display: { xs: 'none', lg: 'inline-flex' }, maxWidth: "200px" }}>
                Welcome {user.name}
            </Typography>

            <Tooltip title="Open settings">
                <IconButton  onClick={handleOpenUserMenu} size="large" aria-label="account of current user"
                    aria-controls="menu-appbar" aria-haspopup="true" color="inherit">
                    <AccountCircle />
                </IconButton>
            </Tooltip>

            <Menu sx={{ mt: '45px' }} id="menu-appbar" anchorEl={anchorElUser} keepMounted
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={Boolean(anchorElUser)} onClose={handleCloseUserMenu}>

                <MenuItem component={Link} to="/profile" onClick={handleCloseUserMenu}>
                    <Typography textAlign="center">Profile</Typography>
                </MenuItem>

                <MenuItem component={Link} to="/history" onClick={handleCloseUserMenu}>
                    <Typography textAlign="center">History</Typography>
                </MenuItem>

                {user.account === 2 &&
                    <MenuItem component={Link} to="/institution-users" onClick={handleCloseUserMenu}>
                        <Typography textAlign="center">Institution Config</Typography>
                    </MenuItem>
                }

                <MenuItem onClick={onLogout}>
                    <Typography textAlign="center">Logout</Typography>
                </MenuItem>
            </Menu>
        </Box>
    );
}