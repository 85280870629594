import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Box, List, ListItem, ListItemIcon, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import React from 'react';
import LoadingSpinnerCentred from "../Components/LoadingSpinnerCentred";
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';

function ProductOptions(params)
{
    const { enabled, product } = params;
    const iconColor = enabled ? 'primary' : '';

    console.log(product);

    return (
        <List dense={true}>
            <ListItem disablePadding>
                <ListItemIcon sx={{ minWidth: 30 }}><CheckCircleIcon color='primary' /></ListItemIcon>
                Access to free questions and articles
            </ListItem>
            <ListItem disablePadding>
                <ListItemIcon sx={{ minWidth: 30 }}><CheckCircleIcon color='primary' /></ListItemIcon>
                Access to question history and review system
            </ListItem>
            <ListItem disablePadding disabled={!enabled}>
                <ListItemIcon sx={{ minWidth: 30 }}>
                    {enabled ? <CheckCircleIcon color='primary' /> : <CancelIcon color={iconColor} />}
                </ListItemIcon>
                Access to the full bank of over 900 questions
            </ListItem>
            <ListItem disablePadding disabled={!enabled}>
                <ListItemIcon sx={{ minWidth: 30 }}>
                    {enabled ? <CheckCircleIcon color='primary' /> : <CancelIcon color={iconColor} />}
                </ListItemIcon>
                Full access to all articles and resources
            </ListItem>
            {product?.info && product.info.map((infoItem, index) => (
                <ListItem key={index} disablePadding disabled={!enabled}>
                    <ListItemIcon sx={{ minWidth: 30 }}>
                        <AddCircleRoundedIcon color='primary' />
                    </ListItemIcon>
                    {infoItem}
                </ListItem>
            ))}
        </List>
    );
}

export default function AvailableProducts(params)
{
    const { productId, setProductId, includeFree, orientation, products } = params;

    if (products === null)
        return <LoadingSpinnerCentred topMargin={true} />

    function handleOnChange(e, v)
    {
        setProductId(v);
    }

    return (
        <ToggleButtonGroup fullWidth orientation={orientation} value={productId} onChange={handleOnChange} exclusive aria-label="products">
            {includeFree &&
                <ToggleButton value="" sx={{ textTransform: 'none' }}>
                    <Box sx={{ width: '100%' }}>
                        <Typography component="h1" variant="h6" textAlign="left">No Subscription</Typography>
                        <ProductOptions />
                        <Typography color="success.main" textAlign="right">Free</Typography>
                    </Box>
                </ToggleButton>
            }

            {products && products.map((p, index) => (
                <ToggleButton key={index} value={p.id} sx={{ textTransform: 'none' }} >
                    <Box sx={{ width: '100%' }}>
                        <Typography component="h1" variant="h6" textAlign="left">{p.description}</Typography>
                        <ProductOptions enabled={true} product={p} />
                        <Typography color="success.main" textAlign="right">{p.price}</Typography>
                    </Box>
                </ToggleButton>
            ))}
        </ToggleButtonGroup >
    );
}