import { Container, Typography } from '@mui/material';
import React from 'react';

export default function FAQs(props) {

    return (
        <Container maxWidth="md" sx={{ mt: 8, mb: 6 }}>

            <Typography color="primary" textAlign="center" component="h1" variant="h4" sx={{ fontWeight: 'bold' }}>
                Frequently asked questions
            </Typography>

            <Typography sx={{ fontStyle: 'italic', mt: 4 }}>
                {"What are my rights if I\u2018m not happy with the product?"}
            </Typography>
            <Typography sx={{ mt: 1 }}>
                We offer a 100% money-back guarantee within 30 days of purchase. If you are not completely satisfied, please get in touch via info@pathquestionbank.com and we will refund you.
            </Typography>

            <Typography sx={{ fontStyle: 'italic', mt: 4 }}>
                Do you offer support over the phone?
            </Typography>
            <Typography sx={{ mt: 1 }}>
                {"Unfortunately, because we are busy looking after patients, we can\u2018t offer support over the phone. However, we welcome enquiries by email and will endeavour to get back to you within 24 hours. Please send your emails to info@pathquestionbank.co.uk or you can find us on Twitter: @PathQBank"}
            </Typography>

            <Typography sx={{ fontStyle: 'italic', mt: 4 }}>
                Why can I trust these questions and explanations?
            </Typography>
            <Typography sx={{ mt: 1 }}>
                Each question has been written by Dr. Richard Buka, a haematology registrar based in the UK. Richard has painstakingly researched each question and explanation, and has asked senior colleagues to review any which may be seen as contentious. Because Richard is a trainee himself, he understands the frustration of not understanding, not being able to remember, and not being able to find resources. Hopefully, you will find that his work to overcome this frustration, is of great use to you. Having said this, this is a small enterprise and there may be mistakes. Please make sure you read the original source material (i.e. guidelines) if you are going to use what you have learned in clinical practice.
            </Typography>

            <Typography sx={{ fontStyle: 'italic', mt: 4 }}>
                Are there any essay questions?
            </Typography>
            <Typography sx={{ mt: 1 }}>
                We are working hard on the essay questions and these will be ready shortly.
            </Typography>

            <Typography sx={{ fontStyle: 'italic', mt: 4 }}>
                {"I\u2018ve spotted a mistake, what should I do?"}
            </Typography>
            <Typography sx={{ mt: 1 }}>
                {"Whilst we routinely check and review every single question, it\u2018s possible that some errors have slipped through our net. Our service is a revision aid and should not be used as a clinical guideline. You should consult the reference material before providing clinical care based on our material. Please get in touch with us via info@pathquestionbank.com and we will review the question."}
            </Typography>

            <Typography sx={{ fontStyle: 'italic', mt: 4 }}>
                Can I sign up from outside the UK?
            </Typography>
            <Typography sx={{ mt: 1 }}>
                Yes you can, just sign up. Please be aware that the clinical scenarios and their answers are sometimes specific to the UK and they may not be relevant to your practice.
            </Typography>

            <Typography sx={{ fontStyle: 'italic', mt: 4 }}>
                How are you different to other revision sites and books for FRCPath?
            </Typography>
            <Typography sx={{ mt: 1 }}>
                There is a rich choice of materials online and we are merely another choice. We hope that you can enjoy our service in addition to other services which offer different things. However, we believe that our product is the biggest, most comprehensive question bank for passing FRCPath Part 1.
            </Typography>

            <Typography sx={{ fontStyle: 'italic', mt: 4 }}>
                Do you have any digital pathology resources?
            </Typography>
            <Typography sx={{ mt: 1 }}>
                No, not yet although this is something we will be working on to help with FRCPath Part 2 revision. There are however, fantastic existing resources that you can use.
            </Typography>

            <Typography sx={{ fontStyle: 'italic', mt: 4 }}>
                I would like to write questions for Path Question Bank, how can I contribute?
            </Typography>
            <Typography sx={{ mt: 1 }}>
                We are certainly interested in working with anyone who can contribute questions. Please get in touch with us at info@pathquestionbank.com and we can discuss.
            </Typography>

        </Container>
    );
}