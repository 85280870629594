import React, { useState } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { Alert } from "@mui/material";
import axios from "axios";
import LoadingSpinnerCentred from "../Components/LoadingSpinnerCentred";

export default function Login(params)
{
    const { onLoggedIn, user } = params;
    const { state } = useLocation();
    const [email, setEmail] = useState(null);
    const [errorMsg, setErrorMsg] = useState(state?.errorMsg);
    const [redirect, setRedirect] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [emailConfirmationError, setEmailConfirmationError] = useState(null);
    let navigate = useNavigate();

    if (user)
        return <Navigate to="/profile" />;

    function handleSubmit(event) 
    {
        event.preventDefault();

        setSubmitting(true);
        setErrorMsg(null);
        setEmailConfirmationError(null);

        const data = new FormData(event.currentTarget);
        const loginDto = 
        { 
            email: data.get('email'),
            password: data.get('password')
        };

        axios.post('api/users/login', loginDto, { withCredentials: true }).then(response =>
        {
            onLoggedIn(response.data);
            setSubmitting(false);
            setRedirect(true);
        })
        .catch((error) =>
        {
            setSubmitting(false);
            const errorMsg = error?.response?.data?.message;
            if (errorMsg === "Your account is awaiting e-mail verification.")
                setEmailConfirmationError(errorMsg);
            else setErrorMsg(errorMsg ?? "An error occured");
        });
    }

    function resendConfirmationEmail(event)
    {
        setErrorMsg(null);
        setEmailConfirmationError(null);

        axios.post('api/users/resend-email-confirmation', { email: email }).then(response =>
        {
            onLoggedIn(response.data);
        })
        .catch((error) =>
        {
            setErrorMsg(error?.response?.data ?? "An error occured");
        });
    }

    if (submitting)
        return <LoadingSpinnerCentred topMargin={true} />

    if (redirect)
        return <Navigate to="/profile"/>;

    return (
        <Container component="main" maxWidth="xs">
            <Box sx={{ marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                    <LockOutlinedIcon />
                </Avatar>

                <Typography component="h1" variant="h5">
                    Sign in
                </Typography>

                <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>

                    {errorMsg && <Alert severity="error">{errorMsg}</Alert>}

                    {emailConfirmationError &&
                        <Box>
                            <Alert severity="error">{emailConfirmationError}</Alert>
                            <Button onClick={resendConfirmationEmail} fullWidth variant="contained" sx={{ mt: 1, mb: 1 }}>
                                Resend confirmation email
                            </Button>
                        </Box>
                    }

                    <TextField id="email" name="email" autoComplete="email" label="Email Address"
                        margin="normal" required fullWidth autoFocus onChange={(e) => setEmail(e.target.value)}/>
                    <TextField id="password" name="password" autoComplete="current-password" label="Password"
                        margin="normal" type="password" required fullWidth />

                    <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
                        Sign In
                    </Button>

                    <Grid container>
                        <Grid item xs>
                            <Link href="/forgotten_password" variant="body2">
                                Forgot password?
                            </Link>
                        </Grid>
                        <Grid item>
                            <Link onClick={() => navigate("/register") } component="button" variant="body2">
                                {"Don't have an account? Sign Up"}
                            </Link>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Container>
    );
}