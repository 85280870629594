import { Alert, Autocomplete, Button, Checkbox, Container, FormControl, Grid, InputLabel, ListItemText, MenuItem, OutlinedInput, Select, TextField, Typography } from '@mui/material';
import axios from "axios";
import React, { useEffect, useState } from 'react';
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import AddRemoveList from "../Components/AddRemoveList";
import MarkdownEditView from "../Components/MarkdownEditView";

export default function EditQuestionPage(params)
{
    let navigate = useNavigate();
    const { state } = useLocation();
    const questionData = state !== null ? state.editing : null;

    const [question, setQuestion] = useState(questionData);
    const [questionTypes, setQuestionTypes] = useState([]);
    const [subCategories, setSubCategories] = useState([]);
    const [categories, setCategories] = useState([]);
    const [alertInfo, setAlertInfo] = useState(null);

    const { answers } = question;
    const isExtendedChoice = question.type === 1;

    useEffect(() =>
    {
        fetchQuestionConfigInfo();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function fetchQuestionConfigInfo()
    {
        axios.get('api/questions/types', { withCredentials: true }).then(result =>
        {
            setQuestionTypes(result.data);
        });

        axios.get('api/categories', { withCredentials: true }).then(result =>
        {
            setCategories(result.data);
        });

        fetchQuestionSubCategories(question.category);
    };

    function fetchQuestionSubCategories(questionCategory)
    {
        if (!questionCategory)
        {
            setSubCategories([]);
            return;
        }

        const dto = { category: questionCategory };
        axios.post('api/categories/sub-categories', dto, { withCredentials: true }).then(result =>
        {
            setSubCategories(result.data);
        });
    }

    function setAnswers(answerData)
    {
        setQuestion({ ...question, answers: answerData });
    }

    const getMultipleChoiceAnswer = () =>
    {
        if (answers === undefined || answers.lenth === 0)
            return null;
        const multichoiceAnswer = answers.find(a => a.subQuestionId === 0);
        return multichoiceAnswer === undefined ? null : multichoiceAnswer.correctOptionId;
    }

    function GetTemporaryId(items) {
        for (let i = 1; i < items.length + 1; i++)
            if (!items.some(o => o.id === i))
                return i;
        return items.length + 1;
    }

    function onHandlePropertyChange(event)
    {
        const { name, value } = event.target;
        setQuestion({ ...question, [name]: value });
    }

    function onHandleCategoryChanged(event, value)
    {
        setQuestion({ ...question, category: value, subCategories: [] });
        fetchQuestionSubCategories(value);
    }

    function onHandleSubCategoriesChanged(event)
    {
        setQuestion({ ...question, subCategories: event.target.value });
    }

    function onAddSubQuestion(event)
    {
        const newSubQuestions = question.subQuestions;
        newSubQuestions.push({ id: GetTemporaryId(newSubQuestions), text: "" });
        setQuestion({ ...question, subQuestions: newSubQuestions });
    }

    function onSubQuestionChanged(event, index)
    {
        const newSubQuestions = question.subQuestions;
        newSubQuestions[index].text = event.target.value;
        setQuestion({ ...question, subQuestions: newSubQuestions });
    }

    function onDeleteSubQuestion(index)
    {
        const newSubQuestions = question.subQuestions;
        newSubQuestions.splice(index, 1);

        const newAnswers = question.answers;
        newAnswers.splice(index, 1);

        setQuestion({ ...question, subQuestions: newSubQuestions, answers: newAnswers });
    }

    function onAddOption(event)
    {
        const newOptions = question.options;
        newOptions.push({ id: GetTemporaryId(newOptions), text: "" });
        setQuestion({ ...question, options: newOptions });
    }

    function onOptionChanged(event, index)
    {
        const newOptions = question.options;
        newOptions[index].text = event.target.value;
        setQuestion({ ...question, options: newOptions });
    }

    function onDeleteOption(index)
    {
        const newOptions = question.options;
        newOptions.splice(index, 1);
        setQuestion({ ...question, options: newOptions });
    }

    const onSetMultipleChoiceAnswer = (optionId) => onSetAnswer(0, optionId);
    function onExtendedChoiceAnswerChanged(subQuestionId, optionIndex)
    {
        const optionId = optionIndex === null ? 0 : question.options[optionIndex].id;
        onSetAnswer(subQuestionId, optionId);
    }

    function onSetAnswer(subQuestionId, optionId)
    {
        let newArr = [...answers];
        let item = newArr.find((a) => a.subQuestionId === subQuestionId);
        if (item === undefined)
            newArr.push({ questionId: question.id, subQuestionId: subQuestionId, correctOptionId: optionId });
        else
            item.correctOptionId = optionId;

        setAnswers(newArr);
    }

    async function onSubmitting(event)
    {
        event.preventDefault();
        setAlertInfo(null);
        axios.put("api/questions/update", question, { withCredentials: true }).then(result =>
        {
            navigate(-1);
        })
        .catch(error =>
        {
            setAlertInfo({ type: "error", message: error?.response?.data?.message ?? "An error occured" });
        });
    }

    function getSubQuestionsWithAnswers()
    {
        // This will combine selected answer indexes with the sub-questions
        // Allows the subquestion component to select options based on index
        return question.subQuestions.map(sq => {

            const sqAnswer = answers.find(a => a.subQuestionId === sq.id);
            if (sqAnswer === null || sqAnswer === undefined)
                return sq;

            const sqOption = question.options.find(o => o.id === sqAnswer.correctOptionId);
            if (sqOption === null)
                return sq;

            const optionIndex = question.options.indexOf(sqOption);
            return { id: sq.id, text: sq.text, answerIndex: optionIndex };
        });
    }

    if (params.user === null || !params.user.isAdmin)
        return <Navigate to="/" />;

    const subCategoryLabel = question?.subCategories?.length === 0 ? "Include all Sub-Categories" : "Sub-Categories";

    return (
        <Container component="form" maxWidth="md" sx={{ mt: 4 }} onSubmit={onSubmitting}>

            <Grid container rowSpacing={2} columnSpacing={2}>
                <Grid item xs={6}>
                    <TextField name="name" id="question-name" required label="Name" fullWidth
                        value={question.name} onChange={onHandlePropertyChange} />
                </Grid>
                <Grid item xs={6}>
                    <FormControl fullWidth>
                        <InputLabel id="question-type">Question Type</InputLabel>
                        <Select label="Question Type" name="type" labelId="question-type" value={question.type}
                            onChange={onHandlePropertyChange}>
                            {questionTypes.map(qt => {
                                return <MenuItem key={qt.value} value={qt.value}>{qt.label}</MenuItem>
                            })}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={4}>
                    <Autocomplete disableClearable disablePortal fullWidth options={categories.map(c => c.label)} value={question.category}
                        name="category" onChange={onHandleCategoryChanged}
                        renderInput={(params) => <TextField required {...params} label="Category" />} />
                </Grid>
                <Grid item xs={8}>
                    <FormControl fullWidth>
                        <InputLabel id="sub-categories">{subCategoryLabel}</InputLabel>
                        <Select value={question.subCategories} labelId="sub-categories" input={<OutlinedInput label={subCategoryLabel} />}
                            multiple renderValue={(selected) => selected.map((x) => x).join(', ')} onChange={onHandleSubCategoriesChanged}>
                            {subCategories.map((sc) => (
                                <MenuItem key={sc} value={sc}>
                                    <Checkbox checked={question.subCategories.findIndex((item) => item === sc) >= 0} />
                                    <ListItemText primary={sc} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>

            {alertInfo && <Alert sx={{ mt:2 }} severity={alertInfo.type}>{alertInfo.message}</Alert>}

            <MarkdownEditView onChange={onHandlePropertyChange} children={question.text}
                name="text" label="Question Text" />

            <AddRemoveList title="Options" items={question.options} onAdd={onAddOption} 
                onChange={onOptionChanged} onDelete={onDeleteOption} displayLetterHeading={isExtendedChoice} />

            {   /*Extended choice sub-questions*/
                isExtendedChoice &&
                <AddRemoveList title="Sub Questions" items={getSubQuestionsWithAnswers()} onAdd={onAddSubQuestion}
                    onChange={onSubQuestionChanged} onDelete={onDeleteSubQuestion} maxAnswerIndex={question.options.length}
                    onAnswerChanged={onExtendedChoiceAnswerChanged}/>
            }
           
            {   /*Multiple choice question combo*/
                question.type === 0 &&
                <FormControl fullWidth sx={{ mt: 4 }}>
                    <InputLabel id="question-answer">Question Answer</InputLabel>
                    <Select labelId="question-answer" label="Question Answer" value={getMultipleChoiceAnswer()}
                        onChange={(event) => onSetMultipleChoiceAnswer(event.target.value) }>
                        {question.options.filter(o => o.text !== '').map(function (o)
                        {
                            return (
                                <MenuItem key={o.id} value={o.id}>
                                    <Typography noWrap>{o.text}</Typography>
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>
            }

            <MarkdownEditView onChange={onHandlePropertyChange} children={question.information}
                name="information" label="Question Information" />

            {alertInfo && <Alert sx={{ mt: 4 }} severity={alertInfo.type}>{alertInfo.message}</Alert>}

            <Button type="submit" fullWidth variant="contained" sx={{ mt: 4, mb: 8 }}>
                Submit Changes
            </Button>


        </Container>
    );
}