
export const getAccountLabel = (user) =>
{
    if (user.isAdmin)
        return 'Admin';
    if (user.account === 1)
        return 'Full Access';
    if (user.account === 2)
        return 'Institution Access';
    if (user.isVerified)
        return 'Free';
    return 'Unverified';
}