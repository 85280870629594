import AssignmentIcon from '@mui/icons-material/Assignment';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TreeItem from '@mui/lab/TreeItem';
import TreeView from '@mui/lab/TreeView';
import { Alert, Box, Button, Container, IconButton, Typography } from "@mui/material";
import axios from "axios";
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import GeneralDialog from "../Components/GeneralDialog";
import LoadingSpinnerCentred from "../Components/LoadingSpinnerCentred";

export default function EssayConfig(params)
{
    const { user, isAdmin } = params;
    const [categories, setCategories] = useState(null);
    const [alertInfo, setAlertInfo] = useState(null);
    const [fetchingEssay, setFetchingEssay] = useState(false);
    const [expanded, setExpanded] = useState([]);
    const [dialogInfo, setDialogInfo] = useState(null);

    const restrictedAccess = !user || (user.account === 0 && !user.isAdmin);
    let navigate = useNavigate();

    const disabledWhenNoUserStyle = (flag) => {
        return {
            opacity: !restrictedAccess ? 1 : 0.45,
            pointerEvents: user ? "initial" : "none"
        }
    }

    const fetchEssays = useCallback(() =>
    {
        setCategories(null);
        setFetchingEssay(true);
        axios.get('api/essays/categories', { withCredentials: true }).then(result =>
        {
            setCategories(result.data);
            setFetchingEssay(false);
        })
        .catch((error) =>
        {
            setFetchingEssay(false);
            if (error.response.status === 401)
                navigate("/");
        });

    }, [navigate]);

    useEffect(() => fetchEssays(), [fetchEssays]);

    if (fetchingEssay || categories === null)
    {
        if (alertInfo)
            return <Alert severity={alertInfo.type}>{alertInfo.message}</Alert>
        return <LoadingSpinnerCentred topMargin={true} />
    }

    function onEssaySelected(essay)
    {
        navigate("/essay", { state: { essayId: essay.id, displayName: essay.displayName } });
    }

    function onTreeViewCategoryClick(id)
    {
        if (expanded.some(e => e === id))
            setExpanded(expanded.filter(e => e !== id))
        else setExpanded([...expanded, id]);
    }

    function onDeleteEssay(essay)
    {
        setDialogInfo(null);
        const dto = { id: essay.id };
        axios.delete('api/essays/delete-essay', { data: dto }, { withCredentials: true }).then(res =>
        {
            fetchEssays();
        })
        .catch(error =>
        {
            setAlertInfo({ type: "error", message: error?.response?.data?.message ?? "An error occured" });
        });
    }

    function showDeleteEssayDialog(event, essay)
    {
        event.stopPropagation();
        const dialogInfo =
        {
            title: "Delete essay",
            text: `Are you sure you want to delete ${essay.displayName}?`,
            actions: [{ handler: () => onDeleteEssay(essay), text: "Confirm" }]
        };

        setDialogInfo(dialogInfo);
    }

    function onImportEssay(event)
    {
        const formData = new FormData();
        formData.append('file', event.target.files[0])
        const config = { headers: { 'content-type': 'multipart/form-data' } };

        setAlertInfo(null);
        setCategories(null);
        return axios.post("api/essays/import", formData, config).then((response) =>
        {
            // We may return a report successfully, if it has a message, it's to indicate an error
            if (response.data.messages.some(a => true))
                setAlertInfo({ type: "error", message: response.data.messages[0] });

            fetchEssays();
        })
        .catch(error =>
        {
            setAlertInfo({ type: "error", message: error?.response?.data?.message ?? "An error occured" });
        });
    }

    const categoriesUI = categories.map((category, index) => (
        <TreeItem id={"cat" + category.id} onClick={(e) => onTreeViewCategoryClick("cat" + category.id)}
            sx={{ mt: .5, mb: .5 }} nodeId={"cat" + category.id} label=
            {
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography sx={{ fontWeight: 'inherit', flexGrow: 1 }} component="h3" variant="h6" >{category.name}</Typography>
                </Box>
            }>
            {category.essays.map(essay => (
                <TreeItem id={"ess" + essay.id} sx={{ mt: .5, mb: .5 }} onClick={(e) => onEssaySelected(essay)}
                    nodeId={"ess" + essay.id} icon={<AssignmentIcon fontSize='large' />} label=
                    {
                        <Box sx={[{ display: 'flex', alignItems: 'center' }]}>
                            <Box sx={{ flexGrow: 1 }}>
                                <Typography display='inline' component="h3" variant="h6" sx={{ mr: 1 }}>
                                    {essay.displayName}
                                </Typography>

                            </Box>
                            {isAdmin &&
                                <IconButton sx={{ ml: 1 }} edge="end" onClick={(e) => showDeleteEssayDialog(e, essay)} >
                                    <DeleteIcon />
                                </IconButton>
                            }
                        </Box>
                    }>
                </TreeItem>
            ))}
        </TreeItem>
    ));

    return (
        <Box>
            {isAdmin && 
                <Container maxWidth="xs" sx={{ alignItems: 'centre' }}>
                    <Typography sx={{ marginTop: 8 }} align="center" component="h1" variant="h4" gutterBottom color="primary">
                        {"Essay Management"}
                    </Typography>

                    <Button component="label" variant="contained" sx={{ mt: 3 }}>
                        {"Import"}<input value="" type="file" hidden onChange={onImportEssay} />
                    </Button>
                </Container>
            }

            <Container maxWidth="sm" sx={{ mt: 4 }}>

                {restrictedAccess &&
                    <Box sx={{ p: 3, border: 2, borderRadius: 1, borderColor: 'primary.main' }}>
                        <Typography>{"With the full version you gain access to example essay questions and answers for each of the following categories."}</Typography>
                    </Box>
                }

                <Container maxWidth="sm" sx={disabledWhenNoUserStyle}>
                    <Typography sx={{ mt: 2, ml: 4, mr: 4 }} component="h2" variant="subtitle1" color="primary">
                        Categories
                    </Typography>

                    <TreeView sx={{ ml: 4, mr: 4 }} expanded={expanded} defaultCollapseIcon={<ExpandMoreIcon />} defaultExpandIcon={<ChevronRightIcon />}>
                        {categoriesUI}
                    </TreeView>
                </Container>

            </Container>

            {alertInfo && <Alert severity={alertInfo.type}>{alertInfo.message}</Alert>}
            {dialogInfo && <GeneralDialog dialogInfo={dialogInfo} setDialogInfo={setDialogInfo} />}
        </Box>
    );
}