import { Box } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import axios from "axios";
import React, { useEffect, useState } from 'react';
import { Route, Routes, useLocation, useNavigate, Navigate } from "react-router-dom";
import CategoriesPage from './Admin/CategoriesPage';
import EditArticlePage from "./Admin/EditArticlePage";
import EditQuestionPage from "./Admin/EditQuestionPage";
import EditUserPage from './Admin/EditUserPage';
import QuestionsPage from "./Admin/QuestionsPage";
import UserPage from "./Admin/UserPage";
import BottomAppBar from './AppBar/BottomAppBar';
import ResponsiveAppBar from './AppBar/ResponsiveAppBar';
import ArticlePage from './Article/ArticlePage';
import ResourcesPage from './Article/ResourcesPage';
import AxiosInterceptorsSetup from './Components/AxiosInterceptorsSetup';
import HistoryPage from "./History/HistoryPage";
import QuizResultDetails from './History/QuizResultDetails';
import QuizResultQuestionDetails from './History/QuizResultQuestionDetails';
import AboutPage from './OtherPages/AboutPage';
import FAQs from './OtherPages/FAQs';
import HomePage from './OtherPages/Home';
import PrivacyPolicy from './OtherPages/PrivacyPolicy';
import TOS from './OtherPages/TOS';
import ProductsPage from './OtherPages/ProductsPage';
import PaymentPage from './Payment/PaymentPage';
import PaymentSuccess from './Payment/PaymentSuccess';
import QuizPage from './QuizPage/QuizPage';
import EssayPage from './QuizPage/EssayPage';
import ForgottenPasswordPage from './UserManagement/ForgottenPasswordPage';
import InstitutionUsers from './UserManagement/InstitutionUsers';
import Login from './UserManagement/Login';
import Register from './UserManagement/Register';
import ResetPasswordPage from './UserManagement/ResetPasswordPage';
import UserProfile from './UserManagement/UserProfile';
import VerifyPage from './UserManagement/VerifyPage';
import EssayConfig from './QuizPage/EssayConfig';

const getPages = (user, aQP, setAQP, questionType, setQuestionType) => [
    {
        name: user ? 'Questions' : 'Free demo',
        link: 'questions',
        component: <QuizPage user={user} questionType={questionType} setQuestionType={setQuestionType} />,
    },
    {
        name: 'Resources',
        link: 'resources',
        component: <ResourcesPage user={user} />,
    },
    {
        name: 'About Us',
        link: '/about',
        component: <AboutPage />
    },
    {
        name: 'FAQs',
        link: '/faqs',
        component: <FAQs />
    },
    {
        name: 'Users',
        link: 'users-management',
        component: <UserPage />,
        requires: "admin",
    },
    {
        name: 'Questions',
        link: 'questions-management',
        component: <QuestionsPage aQP={aQP} setAQP={setAQP}/>,
        requires: "admin",
    },
    {
        name: 'Categories',
        link: 'category-management',
        component: <CategoriesPage />,
        requires: "admin",
    },
    {
        name: 'Resources',
        link: 'article-management',
        component: <ResourcesPage isAdmin={true} />,
        requires: "admin",
    },
    {
        name: 'Essays',
        link: 'essay-management',
        component: <EssayConfig user={user} isAdmin={true} />,
        requires: "admin",
    }
]

const bottomAppBarPages =
[
    {
        name: 'Privacy Policy',
        link: '/privacy-policy'
    },
    {
        name: 'Terms of Service',
        link: '/tos'
    },
    {
        name: 'FAQs',
        link: 'faqs'
    },
];

export default function App()
{
    const [user, setUser] = useState(null);
    const [aQP, setAQP] = useState(0); // Hack to remember the admin questions page
    const [questionType, setQuestionType] = useState(""); // Hack to remember the quiz questions type
    const location = useLocation();

    useEffect(() => {
        fetchUser();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function fetchUser()
    {
        setUser(null);

        // If we are redirected from an email, don't try and get the current user using the 
        // jtw since we are explicitly resetting the password or registering a new account
        if (location.pathname === '/password_reset' || location.pathname === '/register')
            return;

        axios.get('api/users/user', { withCredentials: true }).then(response =>
        {
            if (response.status === 200)
                setUser(response.data);
        })
        .catch(error => 
        {
            console.log('Error fetching user - ' + error);
        });
    }

    function AxiosInterceptorNavigate()
    {
        let navigate = useNavigate();
        const [ran, setRan] = useState(false);
        if (!ran)
        {
            AxiosInterceptorsSetup(navigate, () => setUser(null));
            setRan(true);
        }

        return <></>;
    }

    const osDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
    const darkMode = user?.darkMode ?? osDarkMode;
    const theme = createTheme({
        palette:
        {
            mode: darkMode ? "dark" : "light",
            secondary: { main: darkMode ? "#1444B8" : "#0F3287" }
        },
        typography: { "fontFamily": `"Roboto", "Helvetica", "Arial", sans-serif` }
    });

    const permittedPages = getPages(user, aQP, setAQP, questionType, setQuestionType).filter(p =>
    {
        if (!p.requires)
            return true;

        if (user === null)
            return false;

        if (user.isAdmin)
            return true;

        switch (p.requires)
        {
            case "admin": return false;
            case "full": return user.account >= 1;
            case "verified": return user.isVerified;
            default:
                console.log("Unexpected page requirement");
                return false;
        }
    });

    // To ensure the bottom appbar is positioned at the bottom of the page, even on small pages with little content
    const appBarHeight = 70;
    const bottomAppBarHeight = 90;
    const pageHeight = window.innerHeight - appBarHeight * 2 - bottomAppBarHeight + 5;

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <ResponsiveAppBar pages={permittedPages} user={user} onLoggedOut={() => setUser(null)} darkTheme={darkMode} appBarHeight={appBarHeight} />
            {<AxiosInterceptorNavigate />}
            <Box sx={{ minHeight: pageHeight }}>
                <Routes>

                    {permittedPages.map((page) => (
                        <Route key={page.name} path={page.link} element={page.component} />
                    ))}

                    <Route path='/' element={<HomePage user={user} darkMode={darkMode} />} />
                    <Route path='/about' element={<AboutPage user={user} darkMode={darkMode} />} />
                    <Route path='/profile' element={<UserProfile user={user} setUser={setUser} darkMode={darkMode} />} />
                    <Route path='/login' element={<Login onLoggedIn={setUser} user={user} />} />
                    <Route path='/register' element={<Register />} />
                    <Route path='/article' element={<ArticlePage />} />
                    <Route path='/essay' element={<EssayPage />} />
                    <Route path='/history' element={<HistoryPage />} />
                    <Route path='/quiz-details' element={<QuizResultDetails />} />
                    <Route path='/quiz-question-details' element={<QuizResultQuestionDetails />} />
                    <Route path='/verify_user' element={<VerifyPage onLoggedIn={setUser} />} />
                    <Route path='/forgotten_password' element={<ForgottenPasswordPage />} />
                    <Route path='/password_reset' element={<ResetPasswordPage isLoggedIn={user !== null} />} />
                    <Route path='/payment' element={<PaymentPage darkTheme={darkMode} />} />
                    <Route path='/payment/success' element={<PaymentSuccess user={user} setUser={setUser}/>} />
                    <Route path='/questions-management-edit' element={<EditQuestionPage user={user} />} />
                    <Route path='/users-management-edit' element={<EditUserPage user={user} />} />
                    <Route path='/article-edit' element={<EditArticlePage />} />
                    <Route path='/products' element={<ProductsPage user={user} />} />
                    <Route path='/institution-users' element={<InstitutionUsers user={user} />} />
                    <Route path='/privacy-policy' element={<PrivacyPolicy />} />
                    <Route path='/tos' element={<TOS />} />
                    <Route path="*" element={<Navigate to="/" replace />} />
                
                </Routes>
            </Box>

            <BottomAppBar pages={bottomAppBarPages} darkTheme={darkMode} appBarHeight={bottomAppBarHeight} />

        </ThemeProvider>
    );
}