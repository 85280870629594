import { Alert, Box, Button, Container, Grid, Typography } from '@mui/material';
import axios from "axios";
import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import LoadingSpinnerCentred from "../Components/LoadingSpinnerCentred";

// TODO - deduplicate, also in QuizConfig.js QuizResultDetails.js
const _sameQuizRetakeOption = 0;
const _differentQuestionsRetakeOption = 1;

export default function HistoryPage()
{
    const [quizResults, setQuizResults] = useState(null);
    const [alertInfo, setAlertInfo] = useState(null);
    let navigate = useNavigate();

    useEffect(() => fetchQuizHistory(), []);

    function fetchQuizHistory()
    {
        setQuizResults(null);
        axios.get('api/answers/history', { withCredentials: true }).then(res =>
        {
            setQuizResults(res.data.quizResults);
            if (res.data.quizResults.length === 0)
                setAlertInfo({ type: "info", message: "No results" });
        })
        .catch(error =>
        {
            setQuizResults([]);
            setAlertInfo({ type: "error", message: error?.response?.data?.message ?? "An error occured" });
        });
    }

    function onRetakeQuiz(quizResult, option)
    {
        setQuizResults(null);
        const retakeDto = { quizResultId: quizResult.id, retakeOption: option };
        axios.post('api/questions/retake', retakeDto, { withCredentials: true }).then(response =>
        {
            navigate("../questions", { state: { quizData: response.data } });
        })
        .catch(error =>
        {
            setAlertInfo({ type: "error", message: error?.response?.data?.message ?? "An error occured" });
            fetchQuizHistory();
        });
    }

    function onQuizDetails(quizResult)
    {
        navigate("../quiz-details", { state: { quizResultId: quizResult.id } });
    }

    function onRemoveQuiz(quizResult)
    {
        setQuizResults(null);
        const deleteDto = { ids: [quizResult.id] };
        axios.delete('api/answers/remove-quiz', { data: deleteDto }, { withCredentials: true }).then(response =>
        {
            fetchQuizHistory();
        })
        .catch(error =>
        {
            setAlertInfo({ type: "error", message: error?.response?.data?.message ?? "An error occured" });
            fetchQuizHistory();
        });
    }

    if (!quizResults)
        return <LoadingSpinnerCentred topMargin={true} />

    return (
        
        <Container maxWidth="sm" sx={{ alignItems: 'centre' }}>

            <Typography sx={{ marginTop: 8 }} align="center" component="h1" variant="h4" gutterBottom color="primary">
                History
            </Typography>

            <Button sx={{ mt: 2, mb: 4 }} fullWidth variant="contained" onClick={(e) => navigate("../questions")}>
                Do more questions
            </Button>

            {alertInfo && <Alert sx={{ mt: 1 }} severity={alertInfo.type}>{alertInfo.message}</Alert>}

            {quizResults.reverse().map((result) => (
                <Box key={result.id} sx={{ mb:2, border: 3, borderRadius: 1, borderColor: 'primary.main' }}>
                    <Container align="left" maxWidth="sm" >

                        <Typography sx={{ mt: 2 }} component="h6" variant="h6" gutterBottom color="primary.main">
                            Correct Answers: {Math.round(100.0 * result.marks / result.maxMarks)}% ({result.marks}/{result.maxMarks})
                        </Typography>

                        <Typography variant="h6" gutterBottom color="primary.main">
                            Primary Categories: {result.primaryCategories}
                        </Typography>

                        <Typography variant="h6" gutterBottom color="primary.main">
                            Date time: {format(new Date(result.timeStamp), "MMMM do, yyyy (HH:mm)")}
                        </Typography>

                        <Typography variant="h7" gutterBottom color="primary.main">
                            Weak categories: {result.weakCategories}
                        </Typography>

                        <Grid container sx={{ m: 3 }} rowSpacing={{ xs: 1.5 }} columnSpacing={{ xs: 1.5 }}>
                            <Grid item xs={12}>
                                <Button sx={{ mt: 2 }} fullWidth variant="contained" onClick={(e) => onRetakeQuiz(result, _sameQuizRetakeOption)}>
                                    Retake With Same Questions
                                </Button>
                            </Grid>
                            <Grid item xs={12}>
                                <Button fullWidth variant="contained" onClick={(e) => onRetakeQuiz(result, _differentQuestionsRetakeOption)}>
                                    Retake With Different Questions
                                </Button>
                            </Grid>
                            <Grid item xs={6}>
                                <Button fullWidth variant="outlined" onClick={(e) => onQuizDetails(result)}>Details</Button>
                            </Grid>
                            <Grid item xs={6}>
                                <Button fullWidth variant="outlined" onClick={(e) => onRemoveQuiz(result)}>Remove</Button>
                            </Grid>
                        </Grid>
                    </Container>
                </Box>
            ))}

        </Container>
    );
}