import axios from "axios";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import LoadingSpinnerCentred from "../Components/LoadingSpinnerCentred";
import MatchingQuestion from "./MatchingQuestion";
import MultipleChoiceQuestion from "./MultipleChoiceQuestion";
import { Container, Typography, ToggleButtonGroup, ToggleButton, Box } from "@mui/material";
import QuizConfig from "./QuizConfig";
import EssayConfig from "./EssayConfig";

export default function QuizPage(params)
{
    const { user, questionType, setQuestionType } = params;
    const { state } = useLocation();
    const quizDataParam = state !== null ? state.quizData : null;

    const [qIndex, setQIndex] = useState(0);
    const [quizData, setQuizData] = useState(quizDataParam);
    const [userAnswers, setUserAnswers] = useState([]);
    const [answers, setAnswers] = useState([]);
    //const [questionType, setQuestionType] = useState("");
    let navigate = useNavigate();

    function onSumbitMultipleChoiceAnswer(answer)
    {
        const question = quizData.questions[qIndex];
        const correctAnswer = quizData.answers.find(a => a.questionId === question.id).correctOptionId;
        const userAnswer = { questionId: question.id, optionId: answer, correctAnswerId: correctAnswer };
        setUserAnswers([...userAnswers, userAnswer]);
    }

    function onSumbitExtendedChoiceAnswer()
    {
        const question = quizData.questions[qIndex];
        for (const answer of answers)
        {
            const answerData = quizData.answers.find(a => a.questionId === question.id && a.subQuestionId === answer.subQuestionId);
            answer.correctAnswer = answerData.correctOptionId;
        }
            
        const userAnswer = { questionId: question.id, subAnswers: answers };
        setUserAnswers([...userAnswers, userAnswer]);
    }

    function onSkipQuestion()
    {
        const question = quizData.questions[qIndex];
        const skippedAnswerDto = { questionId: question.id, answerId: 0 };
        const newUserAnswers = [...userAnswers, skippedAnswerDto];

        if (qIndex + 1 >= questions.length)
        {
            submitResult(newUserAnswers);
        }
        else
        {
            setUserAnswers(newUserAnswers);
            onNextQuestion();
        }
    }

    function onNextQuestion()
    {
        const newQIndex = qIndex + 1;
        setQIndex(newQIndex);
        setAnswers([]);

        if (newQIndex >= questions.length)
            submitResult(userAnswers);
    }

    function submitResult(completeUserAnswers)
    {
        if (user === null)
        {
            navigate("/register", { state: { alertInfo: { type: "success", message: "You've completed the free evaluation questions, sign up for all the good stuff" } } });
            return;
        }

        const quizResultDto = { results: completeUserAnswers, categories: quizData.categories };

        axios.post('api/answers/submit-quiz', quizResultDto, { withCredentials: true }).then(response =>
        {
            navigate("/history");
        })
        .catch(error =>
        {
            navigate("/history");
        });
    }

    // No questions, so display questions configuraton
    if (quizData === null || quizData.questions.length === 0)
    {
        return (
            <Container component="main" sx={{ maxWidth: "md" }} >
                <Typography textAlign="center" sx={{ mt: 8 }} component="h1" variant="h4" gutterBottom color="primary">
                    {user ? "Configure Your Questions" : "Free Questions"}
                </Typography>

                <Box sx={{ marginTop: 4, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <ToggleButtonGroup color="primary" value={questionType} aria-label="Platform"
                        exclusive onChange={e => setQuestionType(e.target.value)} >
                        <ToggleButton value="">Multiple choice</ToggleButton>
                        <ToggleButton value="essays">Essay examples</ToggleButton>
                    </ToggleButtonGroup>
                </Box>

                {questionType === "essays" ?
                    <EssayConfig user={user} />
                    :
                    <QuizConfig user={user} onFinishedConfig={setQuizData} />
                }
                
            </Container>
        );
    }

    // Quiz has been finished, wait until submitted
    const questions = quizData.questions;
    if (qIndex >= questions.length)
        return <LoadingSpinnerCentred topMargin={true} />

    // Display multiple-choice question
    const question = questions[qIndex];
    if (question.type === 0)
    {
        return <MultipleChoiceQuestion qIndex={qIndex} questions={questions} userAnswers={userAnswers}
            onSkipQuestion={onSkipQuestion} onNextQuestion={onNextQuestion}
            onSumbitAnswer={onSumbitMultipleChoiceAnswer} user={user}/>
    }

    return <MatchingQuestion qIndex={qIndex} questions={questions} answers={answers} setAnswers={setAnswers}
        userAnswers={userAnswers} onSkipQuestion={onSkipQuestion} onNextQuestion={onNextQuestion}
        onSumbitAnswer={onSumbitExtendedChoiceAnswer} user={user}/>
}