import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import React from "react";
import CustomMarkdown from "../Components/CustomMarkdown";

function GetLabel(params) {
    
    return (
        <CustomMarkdown children={params.label} noParagraphMargins={true} />
    );
}

export default function RadioResult(params) 
{
    let style = { '& .MuiSvgIcon-root': { fontSize: 30 }  };
    let radioColour = 'primary';
    let disableRadio = false;

    if (params.value === parseInt(params.correctAnswer)) {
        style = [style, { color: 'green' }];
        radioColour = 'success';
    }
    else if (params.value === parseInt(params.userAnswer)) {
        style = [style, { color: 'red' }];
        radioColour = 'error';
    }
    else if (params.userAnswer !== null) {
        disableRadio = true;
    }

    return (
        <FormControlLabel
            value={params.value}
            control={<Radio color={radioColour} />}
            label={GetLabel(params)}
            sx={style}
            disabled={disableRadio}>
        </FormControlLabel>
    ); 
}