import { Container, Typography } from '@mui/material';
import React from 'react';

export default function TOS(props) {

    return (
        <Container maxWidth="md" sx={{ mt: 8, mb: 6 }}>

            <Typography color="primary" textAlign="center" component="h1" variant="h4" sx={{ fontWeight: 'bold' }}>
                Terms of Service
            </Typography>

            <Typography component="h2" variant="h6" fontWeight="bold" sx={{ mt: 4 }}>
                {"Introduction"}
            </Typography>
            <Typography sx={{ mt: 1 }}>
                Welcome to www.pathquestionbank.com which is a website owned and run by Smartemis Limited. This is an online study website for the use of health professionals and health students.
            </Typography>
            <Typography sx={{ mt: 1 }}>
                Use of this site is conditional on accepting the Terms of Service for use, please ensure that you read through them thoroughly and understand them prior to using this site. 
            </Typography>
            <Typography sx={{ mt: 1 }}>
                The Terms of Service apply to the use of the services provided by Smartemis Limited. If you do not agree to be bound by the Terms of Service do not use this website.
            </Typography>
            <Typography sx={{ mt: 1 }}>
                Terms of Service may change over time; those applicable to subscribers are those which are current and agreed to when a user subscribes.
            </Typography>
            <Typography sx={{ mt: 1 }}>
                Both the website and the subscription process are in English only. If you have a question regarding the Terms of Service please do not hesitate to contact us at info@pathquestionbank.com
            </Typography>

            <Typography component="h2" variant="h6" fontWeight="bold" sx={{ mt: 4 }}>
                {"Subsciption"}
            </Typography>
            <Typography sx={{ mt: 1 }}>
                The fees for access to www.pathquestionbank.com are indicated on the website. Subscribers are required to pay in pounds sterling. If you are paying from a non-UK pound sterling account then your card provider will calculate the exchange rate of your transaction.
            </Typography>
            <Typography sx={{ mt: 1 }}>
                If you wish to cancel any subscription, we offer a 30 day full refund if you are not completely satisfied. Any cancellations and refunds thereafter will be at the discretion of Smartemis Limited. 
            </Typography>
            <Typography sx={{ mt: 1 }}>
                You undertake to us that all the details you give to us while using the website are correct in particular that the credit or debit card you are using is your own and that there are sufficient funds to cover the cost of the service.
            </Typography>

            <Typography component="h2" variant="h6" fontWeight="bold" sx={{ mt: 4 }}>
                {"Usage of Website"}
            </Typography>
            <Typography sx={{ mt: 1 }}>
                By using our website, whether as a registered user or non-registered user, you are indicating that you accept these Terms of Service and agree to abide by them.
            </Typography>
            <Typography sx={{ mt: 1 }}>
                Accounts are personal to the subscriber and for their sole and non-commercial home use. Passwords and other log on details must not be passed to others. We have the right to take action if this requirement is not adhered to including, but not limited to, immediate termination of your subscription.
            </Typography>
            <Typography sx={{ mt: 1 }}>
                If you use or attempt to use the service for purposes other than personal, individual revision, including but not limited to copying questions, answers or notes, or attempt to tamper, hack, modify or otherwise use the website in any way that is likely to compromise or corrupt the security or functionality of the service provided by Smartemis Limited your account will be terminated and you will be subject to damages and other penalties, including criminal prosecution where available.
            </Typography>
            <Typography sx={{ mt: 1 }}>
                Whilst we will do our utmost to ensure that the website is available at all times, access may be restricted at times for site maintenance or upgrades. Access may also be interrupted due to technical problems. We will always try to resolve these as soon as possible but we cannot accept responsibility for any loss or damages that may result from this. 
            </Typography>

            <Typography component="h2" variant="h6" fontWeight="bold" sx={{ mt: 4 }}>
                {"External links"}
            </Typography>
            <Typography sx={{ mt: 1 }}>
                For the convenience of our users, www.pathquestionbank.com contains links to internal sites. The inclusion of a link does not imply endorsement or approval of the site or its contents which is independent of Smartemis Limited and over which we have no control. As such we can accept no responsibility for the contents or any loss or damages that may result from using these external sites.
            </Typography>

            <Typography component="h2" variant="h6" fontWeight="bold" sx={{ mt: 4 }}>
                {"Copyright"}
            </Typography>
            <Typography sx={{ mt: 1 }}>
                The www.pathquestionbank.com site is copyright protected and it's content, design, layout, databases or graphics shall not be reproduced, stored in any form or by any means including but not limited to electronic, paper, mechanical, photocopying, recording, or broadcasting, nor shall the site be shown in a public gathering. You may only use the material available for your own personal non-commercial home use provided that it is not changed and copyright notices remain; Smartemis Limited retains the intellectual property rights on all material from their website. You shall not create any derivative work or make any other adaptation, without our prior written consent.
            </Typography>
            <Typography sx={{ mt: 1 }}>
                If you use or attempt to use the service for purposes other than personal individual revision, including but not limited to copying questions, copying answers, copying notes, tampering, hacking, modifying or otherwise corrupting the security or functionality of the service provided by Smartemis Limited, your account will be terminated and you will be subject to damages and other penalties, including criminal prosecution where available.
            </Typography>
            <Typography sx={{ mt: 1 }}>
                All rights not explicitly granted in these Terms of Service or in any written licence remain reserved.
            </Typography>

            <Typography component="h2" variant="h6" fontWeight="bold" sx={{ mt: 4 }}>
                {"Limitation of liability"}
            </Typography>
            <Typography sx={{ mt: 1 }}>
                This website is provided as a resource to aid revision and makes no representation, express or implied, that the drug dosages on the website are correct. Users must therefore check with a recognised formulary such as the British National Formulary prior to prescribing.
            </Typography>
            <Typography sx={{ mt: 1 }}>
                Whilst reasonable care is taken to ensure the accuracy of information on this site, to the fullest extent permitted by law the material and information displayed on our website is provided without any guarantees, conditions or warranties as to the correctness, accuracy, reliability, usefulness or otherwise of information provided.
            </Typography>
            <Typography sx={{ mt: 1 }}>
                Medical knowledge is constantly evolving and to the fullest extent permitted by law, Smartemis Limited does not accept any responsibility or legal liability for any errors or the misuse or misapplication of material on the website.
            </Typography>
            <Typography sx={{ mt: 1 }}>
                Smartemis Limited shall not be liable to users of this site by reason of any representation or any implied warranty, condition or other term, or any duty at common law, or under the express terms of the contract for any loss of profit or any direct or indirect, special or consequential loss, damage, costs, expenses or other claims of any kind (whether caused by the negligence of Smartemis Limited, its servants or agents or otherwise) which arise out of or in connection with the revision questions and other information available on this website except as expressly provided in these conditions.
            </Typography>
            <Typography sx={{ mt: 1 }}>
                Smartemis Limited shall not be liable to users or others or be deemed in breach of contract by reason of any delay or failure to perform any of its obligations in relation to the website if this was due to any cause beyond the reasonable control of PassMedicine Limited.
            </Typography>
            <Typography sx={{ mt: 1 }}>
                In no event shall Smartemis Limited assume total liability to any user for all damages, losses, and other causes of action (whether in contract or not) exceed the amount paid to Smartemis Limited for use of this site. The maximum liability for any claim from a user shall not exceed the current subscription fee which has been paid.
            </Typography>

            <Typography component="h2" variant="h6" fontWeight="bold" sx={{ mt: 4 }}>
                {"Privacy"}
            </Typography>
            <Typography sx={{ mt: 1 }}>
                All personal data collected by Smartemis Limited is governed by our privacy policy. By using this site you consent to use of personal data in accordance with the privacy policy.
            </Typography>

            <Typography component="h2" variant="h6" fontWeight="bold" sx={{ mt: 4 }}>
                {"Law governing Terms of Service"}
            </Typography>
            <Typography sx={{ mt: 1 }}>
                These Terms of Service shall be construed and governed exclusively by the laws of England and Wales. However we retain the right to bring proceedings for a breach of these Terms of Service in any relevant country.
            </Typography>
            <Typography sx={{ mt: 1 }}>
                These Terms of Service do not otherwise affect your statutory rights.
            </Typography>

        </Container>
    );
}