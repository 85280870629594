import React from "react";
import { CircularProgress, Box } from '@mui/material';

export default function LoadingSpinnerCentred(params)
{
    const marginTop = params.topMargin ? 12 : 0;
    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: marginTop }}>
            <CircularProgress />
        </Box>
    );
}