import { Alert, Box, Container, Typography } from '@mui/material';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import axios from "axios";
import React, { useEffect, useState } from 'react';
import { useLocation } from "react-router-dom";
import LoadingSpinnerCentred from "../Components/LoadingSpinnerCentred";
import PaymentForm from "./PaymentForm";

export default function PaymentPage(params)
{
    const darkTheme = params.darkTheme;
    const { state } = useLocation();
    const [stripeConfig, setStripeConfig] = useState(null);
    const [publishableKey, setPublishableKey] = useState(null);
    const [productInfo, setProductInfo] = useState(null);
    const [alertInfo, setAlertInfo] = useState(state?.alertInfo);

    useEffect(() =>
    {
        const request = state.isSubscription ? "create-subscription" : "create-payment-intent";
        axios.post('api/payments/' + request, { productId: state.productId }).then(result =>
        {
            const config =
            {
                clientSecret: result.data.clientSecret,
                appearance: { theme: darkTheme ? 'night' : 'stripe' }
            };

            setProductInfo(result.data);
            setStripeConfig(config);
        })
        .catch((error) =>
        {
            setAlertInfo({ type: "error", message: error?.response?.data?.message ?? "An error occured" });
        });

        axios.get('api/payments/config').then(response =>
        {
            setPublishableKey(response.data.publishableKey);
        });

    }, [darkTheme, state]);

    if (productInfo === null || stripeConfig === null || publishableKey === null)
    {
        if (alertInfo)
        {
            return (
                <Container maxWidth="xs" sx={{ mt: 6 }}>
                    <Alert sx={{ mt: 4 }} severity={alertInfo.type}>{alertInfo.message}</Alert>
                </Container>
            );
        }

        return <LoadingSpinnerCentred topMargin={true}/>
    }

    const stripePromise = loadStripe(publishableKey);

    return (
        <Box component="main" sx={{ alignItems: 'center', flexWrap: 'wrap' }}>
            <Container maxWidth="sm" sx={{ mt: 8 }}>
                {alertInfo && <Alert sx={{ mb: 2 }} severity={alertInfo.type}>{alertInfo.message}</Alert>}
                <Typography inline component="h2" variant="h5" sx={{ fontWeight: 'bold' }}>{productInfo.description}</Typography>
                <Typography inline color="success.main" component="h1" variant="h4" sx={{ fontWeight: 'bold' }}>{productInfo.price}</Typography>
            </Container>

            <Elements stripe={stripePromise} options={stripeConfig} >
                <PaymentForm/>
            </Elements>
        </Box>
    );
  }