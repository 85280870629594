import { Box, Link, Alert, Container, Grid, TextField, Autocomplete, Button, Typography } from '@mui/material';
import axios from "axios";
import React, { useEffect, useState, useCallback } from "react";
import { useLocation } from "react-router-dom";
import LoadingSpinnerCentred from "../Components/LoadingSpinnerCentred";
import MarkdownEditView from "../Components/MarkdownEditView";

export default function EditArticlePage()
{
    const { state } = useLocation();
    const [article, setArticle] = useState(null);
    const [categories, setCategories] = useState(null);
    const [requirements, setRequirements] = useState(null);
    const [alertInfo, setAlertInfo] = useState(null);

    const fetchFullArticleAndCategories = useCallback(() =>
    {
        setArticle(null);
        setAlertInfo(null);
        setCategories(null);

        // Fetch the list of possible requirements for viewing this article (free, licenced, admin etc)
        axios.get('api/categories/requirements', { withCredentials: true }).then(res =>
        {
            setRequirements(res.data);
        })
        .catch(error =>
        {
            setAlertInfo({ type: "error", message: error?.response?.data?.message ?? "An error occured" });
        });

        // Fetch list of all categories (uncategoriesd) and their ids
        axios.get('api/articles/category-list', { withCredentials: true }).then(res =>
        {
            setCategories(res.data);
        })
        .catch(error =>
        {
            setAlertInfo({ type: "error", message: error?.response?.data?.message ?? "An error occured" });
        });

        // Fetch the full article for editing
        axios.get('api/articles/' + state.articleId, { withCredentials: true }).then(res =>
        {
            setArticle(res.data);
        })
        .catch(error =>
        {
            setAlertInfo({ type: "error", message: error?.response?.data?.message ?? "An error occured" });
        });

    }, [state]);

    useEffect(() => fetchFullArticleAndCategories(), [fetchFullArticleAndCategories]);

    function onUpdateArticle()
    {
        setArticle(null);
        axios.post('api/articles/update', article, { withCredentials: true }).then(res =>
        {
            fetchFullArticleAndCategories();
        })
        .catch(error =>
        {
            setAlertInfo({ type: "error", message: error?.response?.data?.message ?? "An error occured" });
            fetchFullArticleAndCategories();
        });
    }

    function onCategoryChanged(event, value)
    {
        const cat = categories.find(c => c.name === value);
        if (cat)
            setArticle({ ...article, articleCategoryId: cat.id });
    }

    function onRequirementChanged(event, value)
    {
        const rId = requirements.find(c => c.label === value).id;
        setArticle({ ...article, requirement: rId });
    }

    if (alertInfo)
    {
        return (
            <Container maxWidth="sm" sx={{ mt: 8, alignItems: 'centre' }}>
                <Alert severity={alertInfo.type} > {alertInfo.message}</Alert>
            </Container>
        ); 
    }

    if (!article || !categories)
        return <LoadingSpinnerCentred topMargin={true} />

    const selectedCategory = categories.find(c => c.id === article.articleCategoryId);
    const selectedRequirement = requirements.find(r => r.id === article.requirement);

    return (
        <Container maxWidth="md" sx={{ alignItems: 'centre' }}>

            <TextField label="Title" value={article.title} variant="outlined" multiline fullWidth sx={{ mt: 8 }}
                onChange={(e) => setArticle({ ...article, title: e.target.value })}
                inputProps={{ style: { fontSize: 26 } }} size="large" />

            <TextField label="Sub-Title" value={article.subTitle} variant="outlined" multiline fullWidth sx={{ mt: 3 }}
                onChange={(e) => setArticle({ ...article, subTitle: e.target.value })}
                inputProps={{ style: { fontSize: 18 } }} size="large" />

            <Grid container rowSpacing={2} columnSpacing={2} sx={{ mt: 1 }} alignItems="center" justifyContent="center">
                <Grid item xs={6}>
                    <Autocomplete disableClearable disablePortal fullWidth value={selectedCategory}
                        options={categories} getOptionLabel={(category) => category.name} onInputChange={onCategoryChanged}
                        renderInput={(params) => <TextField required {...params} label="Category" />} />
                </Grid>

                <Grid item xs={6}>
                    <Autocomplete disableClearable disablePortal fullWidth value={selectedRequirement}
                        options={requirements} getOptionLabel={(r) => r.label} onInputChange={onRequirementChanged}
                        renderInput={(params) => <TextField required {...params} label="Status" />} />
                </Grid>

                <Grid item xs={6}>
                    <Button sx={{ mt: 1 }} onClick={onUpdateArticle} fullWidth variant="contained">Update</Button>
                </Grid>
            </Grid>

            { article.documentUrl
                ?
                <Box sx={{ mt: 2 }}>
                    <Typography component="h3" variant="h6">Document URL</Typography>
                    <Link href={article.documentUrl} target="_blank" >{article.documentUrl}</Link>
                </Box>
                :
                <MarkdownEditView onChange={(e) => setArticle({ ...article, body: e.target.value })}
                    children={article.body} label="Article Body" />
            }
 
        </Container>
    );
}