
export function ToChunks(array, chunkSize)
{
    let i = 0;
    const result = [];
    while (i < array.length) {
        result.push(array.slice(i, i + chunkSize));
        i += chunkSize;
    }

    return result;
}