import { Container, Alert, Typography, Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import CustomMarkdown from "../Components/CustomMarkdown";
import LoadingSpinnerCentred from "../Components/LoadingSpinnerCentred";

export default function EssayPage()
{
    const { state } = useLocation();
    const [loading, setLoading] = useState(true);
    const [showAnswer, setShowAnswer] = useState(false);
    const [alertInfo, setAlertInfo] = useState(null);
    const [essay, setEssay] = useState(null);
    let navigate = useNavigate();

    function fetchEssay()
    {
        if (!state?.essayId)
        {
            navigate("/");
            return;
        }

        setLoading(true);
        axios.get('api/essays/' + state.essayId, { withCredentials: true }).then(response =>
        {
            setEssay(response.data);
            setLoading(false);
        })
        .catch(error =>
        {
            setAlertInfo({ type: "error", message: error?.response?.data?.message ?? "An error occured" });
            setLoading(false);
        });
    }

    useEffect(() => fetchEssay(), []);

    if (loading)
    {
        return (
            <Container maxWidth="md" sx={{ alignItems: 'centre' }}>
                <Typography sx={{ mt: 8, mb:4 }} align="center" component="h1" variant="h4" gutterBottom color="primary">
                    {state?.displayName}
                </Typography>
                <LoadingSpinnerCentred marginTop={true} />
            </Container>
        );
    }

    if (alertInfo)
    {
        return (
            <Container maxWidth="sm" sx={{ mt: 8, alignItems: 'centre' }}>
                <Alert severity={alertInfo.type}> {alertInfo.message}</Alert>
            </Container>
        );
    }

    if (!essay)
        return <Typography sx={{ marginTop: 2 }} align="center" component="h1" variant="h6" gutterBottom>No essay found</Typography>;

    //console.log(essay.)

    return (
        <Container maxWidth="md" sx={{ alignItems: 'centre' }}>

            <Typography sx={{ marginTop: 8 }} align="center" component="h1" variant="h4" gutterBottom color="primary">
                {essay.displayName}
            </Typography>

            <CustomMarkdown children={essay.questionText} />

            <Container maxWidth="xs">
                <Button fullWidth variant="contained" sx={{ mt: 4, mb: 2 }} onClick={() => setShowAnswer(!showAnswer)}>
                    {showAnswer ? "Hide Answer" : "Show Answer"}
                </Button>
            </Container>
            

            {showAnswer && <CustomMarkdown children={essay.questionSuggestedAnswer} />}
            
        </Container>
    );
}