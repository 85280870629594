import AddIcon from '@mui/icons-material/Add';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TreeItem from '@mui/lab/TreeItem';
import TreeView from '@mui/lab/TreeView';
import { Alert, Box, Container, IconButton, Typography } from '@mui/material';
import axios from "axios";
import React, { useEffect, useState } from 'react';
import GeneralDialog from "../Components/GeneralDialog";
import LoadingSpinnerCentred from "../Components/LoadingSpinnerCentred";

export default function CategoriesPage()
{
    const [categories, setCategories] = useState(null);
    const [dialogInfo, setDialogInfo] = useState(null);
    const [alertInfo, setAlertInfo] = useState(null);

    useEffect(() => fetchCategories(), []);

    function fetchCategories()
    {
        setCategories(null);
        axios.get('api/categories/config', { withCredentials: true }).then(result =>
        {
            setCategories(result.data);
        })
        .catch((error) =>
        {
            setAlertInfo({ type: "error", message: error?.response?.data?.message ?? "An error occured" });
        });
    };

    function showAddCategoryDialog(parentCategoryId)
    {
        const dialogInfo =
        {
            hasTextEntry: true,
            title: "Add category",
            actions: [{ handler: (categoryName) => onAddCategory(categoryName, parentCategoryId), text: "Confirm" }]
        };

        setDialogInfo(dialogInfo);
    }

    function showDeleteCategoryDialog(event, category, isPrimary)
    {
        event.stopPropagation();

        const dialogInfo =
        {
            title: "Delete category",
            text: `Are you sure you want to try and delete ${category.label}? Note this will only be allowed if there are no questions using this category.`,
            actions: [{ handler: () => onDeleteCategory(category, isPrimary), text: "Confirm" }]
        };

        setDialogInfo(dialogInfo);
    }

    function showEditCategoryDialog(event, category, isPrimary)
    {
        event.stopPropagation();

        const dialogInfo =
        {
            initialText: category.label,
            hasTextEntry: true,
            title: "Edit category",
            actions: [{ handler: (categoryName) => onEditCategory(categoryName, category, isPrimary), text: "Confirm" }]
        };

        setDialogInfo(dialogInfo);
    }

    function onAddCategory(categoryName, parentCategoryId)
    {
        setDialogInfo(null);
        const dto = { name: categoryName, parentCategoryId: parentCategoryId };
        axios.post('api/categories/add', dto, { withCredentials: true }).then(res =>
        {
            fetchCategories();
        })
        .catch(error =>
        {
            setAlertInfo({ type: "error", message: error?.response?.data?.message ?? "An error occured" });
        });
    }

    function onDeleteCategory(category, isPrimary)
    {
        setDialogInfo(null);
        const dto = { id: category.id, isPrimary: isPrimary };
        axios.delete('api/categories/delete', { data: dto }, { withCredentials: true }).then(res =>
        {
            fetchCategories();
        })
        .catch(error =>
        {
            setAlertInfo({ type: "error", message: error?.response?.data?.message ?? "An error occured" });
        });
    }

    function onEditCategory(categoryName, category, isPrimary)
    {
        setDialogInfo(null);
        const dto = { id: category.id, name: categoryName, isPrimary: isPrimary };
        axios.post('api/categories/update', dto, { withCredentials: true }).then(res =>
        {
            fetchCategories();
        })
        .catch(error =>
        {
            setAlertInfo({ type: "error", message: error?.response?.data?.message ?? "An error occured" });
        });
    }

    if (!categories)
        return <LoadingSpinnerCentred topMargin={true} />

    return (
        <Box>
            <Container maxWidth="sm" sx={{ mt: 8 }}>

                <Typography sx={{ marginTop: 8 }} align="center" component="h1" variant="h4" gutterBottom color="primary">
                    Question Categories
                </Typography>

                <TreeView defaultCollapseIcon={<ExpandMoreIcon />} defaultExpandIcon={<ChevronRightIcon />}>
                    {categories.map((category, index) => (
                        <TreeItem key={index} sx={{ mt: .5, mb: .5 }} nodeId={"pCat" + index} label=
                            {
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Typography sx={{ fontWeight: 'inherit', flexGrow: 1 }} component="h3" variant="h6" >
                                        {category.label}
                                    </Typography>
                                    <IconButton edge="end" onClick={(e) => showEditCategoryDialog(e, category, true)} >
                                        <EditIcon />
                                    </IconButton>
                                    <IconButton sx={{ ml: 1 }} edge="end" onClick={(e) => showDeleteCategoryDialog(e, category, true)} >
                                        <DeleteIcon />
                                    </IconButton>
                                 </Box>
                            }>

                            {category.subCategories.map((subCat, subIndex) => (
                                <TreeItem key={subIndex} sx={{ mt: .5, mb: .5 }} nodeId={"sCat" + subIndex} label=
                                    {
                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                            <Typography sx={{ fontWeight: 'inherit', flexGrow: 1 }} component="h3" variant="h6" >
                                                {subCat.label}
                                            </Typography>
                                            <IconButton edge="end" onClick={(e) => showEditCategoryDialog(e, subCat, false)} >
                                                <EditIcon />
                                            </IconButton>
                                            <IconButton sx={{ ml: 1 }} edge="end" onClick={(e) => showDeleteCategoryDialog(e, subCat, false)} >
                                                <DeleteIcon />
                                            </IconButton>
                                         </Box>
                                    }>
                                </TreeItem>
                            ))}

                            <TreeItem sx={{ mt: .5, mb: .5 }} onClick={(e) => showAddCategoryDialog(category.id)}
                                nodeId={"addSubCat" + index} icon={<AddIcon color="primary" fontSize='large' />} label=
                                {
                                    <Typography color="primary" display='inline' component="h3" variant="h6" sx={{ mr: 2 }}>
                                        Add sub-category
                                    </Typography>
                                }>
                            </TreeItem>

                        </TreeItem>
                    ))}

                    <TreeItem sx={{ mt: .5, mb: .5 }} onClick={(e) => showAddCategoryDialog(null)}
                        nodeId={"addCat"} icon={<AddIcon color="primary" fontSize='large' />} label=
                        {
                            <Typography color="primary" display='inline' component="h3" variant="h6" sx={{ mr: 2 }}>
                                Add category
                            </Typography>
                        }>
                    </TreeItem>

                </TreeView>

                {alertInfo && <Alert sx={{ mt: 2 }} severity={alertInfo.type}>{alertInfo.message}</Alert>}

            </Container>

            {dialogInfo && <GeneralDialog dialogInfo={dialogInfo} setDialogInfo={setDialogInfo} />}
        </Box>
    );
}