import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Box, Button, Container, Grid, Typography } from '@mui/material';
import axios from "axios";
import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import LoadingSpinnerCentred from "../Components/LoadingSpinnerCentred";

export default function PaymentSuccess(params)
{
    const { setUser } = params;
    const [email, setEmail] = useState(null);
    useEffect(() => fetchUser(), []);
    let navigate = useNavigate();

    const urlParams = new URLSearchParams(window.location.search);
    const paymentIntentId = urlParams.get("payment_intent");

    function fetchUser()
    {
        axios.post('api/payments/check-user-sub', { paymentIntentId: paymentIntentId }, { withCredentials: true }).then(response =>
        {
            setUser(response.data);
            setEmail(response.data.email);
        })
        .catch(error =>
        {
            setEmail("you");
        });
    }

    if (email === null)
        return <LoadingSpinnerCentred topMargin={true}/>

    return (
        <Container component="main" maxWidth="xs">
            <Box sx={{ marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <CheckCircleIcon sx={{ width: 60, height: 60 }} color="success"/>

                <Typography textAlign="center" component="h1" variant="h4" color="success.main">Payment Successful</Typography>
                <Typography textAlign="center" >A receipt with your transaction id has been emailed to {email}</Typography>
            </Box>

            <Grid container sx={{ mt: 4, mb: 4 }} columnSpacing={{ xs: 4 }}>
                <Grid item xs={6}>
                    <Button fullWidth variant="contained" onClick={() => navigate("/profile")}>User profile</Button>
                </Grid>
                <Grid item xs={6}>
                    <Button fullWidth variant="outlined" onClick={() => navigate("/questions")}>Questions</Button>
                </Grid>
            </Grid>

        </Container>
    );
  }