import AddIcon from '@mui/icons-material/Add';
import ArticleIcon from '@mui/icons-material/Article';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/Download';
import EditIcon from '@mui/icons-material/Edit';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SearchIcon from '@mui/icons-material/Search';
import TreeItem from '@mui/lab/TreeItem';
import TreeView from '@mui/lab/TreeView';
import { Alert, Box, Button, Container, Grid, IconButton, InputAdornment, Menu, MenuItem, TextField, Typography } from '@mui/material';
import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import GeneralDialog from "../Components/GeneralDialog";
import LoadingSpinnerCentred from "../Components/LoadingSpinnerCentred";

export default function ResourcesPage(params) 
{
    const { user, isAdmin } = params;
    const [filteredRootCategory, setFilteredRootCategory] = useState(null);
    const [fetchingArticle, setFetchingArticle] = useState(false);
    const [rootCategory, setRootCategory] = useState(null);
    const [dialogInfo, setDialogInfo] = useState(null);
    const [alertInfo, setAlertInfo] = useState(null);
    const [expanded, setExpanded] = useState([]);
    let navigate = useNavigate();

    const [anchorEl, setAnchorEl] = useState(null);
    const open = anchorEl;

    function handleClick(event)
    {
        setAnchorEl(event.currentTarget);
    }

    function handleClose()
    {
        setAnchorEl(null);
    }

    const fetchArticles = useCallback(() =>
    {
        setRootCategory(null);
        setAlertInfo(null);

        const request = isAdmin ? 'api/articles/categories-admin' : 'api/articles/categories'
        axios.get(request, { withCredentials: true }).then(res =>
        {
            const sortedCategories = sortAlpabetically(res.data);
            setRootCategory(sortedCategories);
            if (res.data.length === 0)
                setAlertInfo({ type: "info", message: "No articles" });
        })
        .catch(error =>
        {
            setAlertInfo({ type: "error", message: error?.response?.data?.message ?? "An error occured" });
        });
    }, [isAdmin]);

    useEffect(() => fetchArticles(), [fetchArticles]);

    function sortAlpabetically(category)
    {
        return {
            ...category,
            subCategories: category.subCategories.map(c => sortAlpabetically(c)).sort((a, b) => a.name.localeCompare(b.name)),
            articles: category.articles.sort((a, b) => a.title.localeCompare(b.title))
        };
    }

    function onArticleSelected(article)
    {
        if (isAdmin)
        {
            navigate("/article-edit", { state: { articleId: article.id } });
            return;
        }

        if (article.documentUrl)
        {
            window.location.assign(article.documentUrl);
            return;
        }

        setFetchingArticle(true);
        axios.get('api/articles/' + article.id, { withCredentials: true }).then(res =>
        {
            setFetchingArticle(false);
            navigate("/article", { state: { article: res.data } });
        })
        .catch(error =>
        {
            setFetchingArticle(false);
            if (error.response.status === 403)
                setDialogInfo({ text: error.response.data.message });
            else
                setAlertInfo({ type: "error", message: error?.response?.data?.message ?? "An error occured" });
        });
    }

    function onCreateArticle(event)
    {
        setAnchorEl(null);
        axios.post("api/articles/create", { withCredentials: true }).then((response) =>
        {
            fetchArticles();
        })
        .catch(error =>
        {
            setAlertInfo({ type: "error", message: error?.response?.data?.message ?? "An error occured" });
        });
    }

    function onImportArticle(event)
    {
        postFormDataAction("api/articles/import", event);
    }

    function onImportArticleAsHtml(event)
    {
        postFormDataAction("api/articles/import-from-html", event);
    }

    function onImportPDFArticle(event)
    {
        postFormDataAction("api/articles/import-pdf-article", event);
    }

    function postFormDataAction(postStr, event)
    {
        const formData = new FormData();
        formData.append('file', event.target.files[0])
        const config = { headers: { 'content-type': 'multipart/form-data' } };

        setAnchorEl(null);
        setRootCategory(null);
        return axios.post(postStr, formData, config).then((response) =>
        {
            fetchArticles();
        })
        .catch(error =>
        {
            setAlertInfo({ type: "error", message: error?.response?.data?.message ?? "An error occured" });
        });
    }

    function showAddCategoryDialog(parentCategoryId)
    {
        const dialogInfo =
        {
            hasTextEntry: true,
            title: "Add category",
            actions: [{ handler: (categoryName) => onAddCategory(categoryName, parentCategoryId), text: "Confirm" } ]
        };

        setDialogInfo(dialogInfo);
    }

    function showDeleteCategoryDialog(event, category)
    {
        event.stopPropagation();
        const dialogInfo =
        {
            title: "Delete category",
            text: `Are you sure you want to delete ${category.name}? All sub-categories and articles in this category will also be deleted.`,
            actions: [{ handler: () => onDeleteCategory(category), text: "Confirm" }]
        };

        setDialogInfo(dialogInfo);
    }

    function showEditCategoryDialog(event, category)
    {
        event.stopPropagation();
        const dialogInfo =
        {
            initialText: category.name,
            hasTextEntry: true,
            title: "Edit category",
            actions: [{ handler: (categoryName) => onEditCategory(categoryName, category), text: "Confirm" }]
        };

        setDialogInfo(dialogInfo);
    }

    function showDeleteArticleDialog(event, article)
    {
        event.stopPropagation();
        const dialogInfo =
        {
            title: "Delete article",
            text: `Are you sure you want to delete ${article.title}? It might be a really good one.`,
            actions: [{ handler: () => onDeleteArticle(article), text: "Confirm" }]
        };

        setDialogInfo(dialogInfo);
    }

    function onAddCategory(categoryName, parentCategoryId)
    {
        setDialogInfo(null);
        const dto = { categoryName: categoryName, parentCategoryId: parentCategoryId };
        axios.post('api/articles/add-article-category', dto, { withCredentials: true }).then(res =>
        {
            fetchArticles();
        })
        .catch(error =>
        {
            setAlertInfo({ type: "error", message: error?.response?.data?.message ?? "An error occured" });
        });
    }

    function onDeleteCategory(category)
    {
        setDialogInfo(null);
        const dto = { id: category.id };
        axios.delete('api/articles/delete-article-category', { data: dto }, { withCredentials: true }).then(res =>
        {
            fetchArticles();
        })
        .catch(error =>
        {
            setAlertInfo({ type: "error", message: error?.response?.data?.message ?? "An error occured" });
        });
    }

    function onEditCategory(categoryName, category)
    {
        setDialogInfo(null);
        const dto = { id: category.id, name: categoryName };
        axios.post('api/articles/update-category', dto, { withCredentials: true }).then(res =>
        {
            fetchArticles();
        })
        .catch(error =>
        {
            setAlertInfo({ type: "error", message: error?.response?.data?.message ?? "An error occured" });
        });
    }

    function onDeleteArticle(article)
    {
        setDialogInfo(null);
        const dto = { id: article.id };
        axios.delete('api/articles/delete-article', { data: dto }, { withCredentials: true }).then(res =>
        {
            fetchArticles();
        })
        .catch(error =>
        {
            setAlertInfo({ type: "error", message: error?.response?.data?.message ?? "An error occured" });
        });
    }

    if (alertInfo)
    {
        return (
            <Container maxWidth="sm" sx={{ mt: 8, alignItems: 'centre' }}>
                <Alert severity={alertInfo.type}> {alertInfo.message}</Alert>
            </Container>
        );
    }

    if (!rootCategory || fetchingArticle)
        return <LoadingSpinnerCentred topMargin={true} />

    function onTreeViewCategoryClick(id)
    {
        if (expanded.some(e => e === id))
            setExpanded(expanded.filter(e => e !== id))
        else setExpanded([...expanded, id]);
    }

    function CreateTreeViewResursive(category)
    {
        const categories = category.subCategories.map(subCategory => (
            <TreeItem id={"cat" + subCategory.id} onClick={(e) => onTreeViewCategoryClick("cat" + subCategory.id)}
                sx={{ mt: .5, mb: .5 }} nodeId={"cat" + subCategory.id} label=
                {
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography sx={{ fontWeight: 'inherit', flexGrow: 1 }} component="h3" variant="h6" >{subCategory.name}</Typography>
                        {isAdmin &&
                            <Box sx={{ display: 'flex' }}>
                                <IconButton edge="end" onClick={(e) => showEditCategoryDialog(e, subCategory)} >
                                    <EditIcon />
                                </IconButton>
                                <IconButton sx={{ ml: 1 }} edge="end" onClick={(e) => showDeleteCategoryDialog(e, subCategory)} >
                                    <DeleteIcon />
                                </IconButton>
                            </Box>
                        }
                    </Box>
                }>
                {CreateTreeViewResursive(subCategory)}
            </TreeItem>
        ));

        const withUnavailableResourceStyle = (article) =>
        {
            if (article.requirementLabel == "Free")
                return { opacity: 1 };
            if (user != null && (user.isAdmin || user.account != 0))
                return { opacity: 1 };
            return { opacity: 0.45 };
        }

        const articles = category.articles.map((article, index) => (
            <TreeItem id={"art" + article.id} sx={{ mt: .5, mb: .5 }} onClick={(e) => onArticleSelected(article)}
                nodeId={"art" + article.id} icon={<ArticleIcon fontSize='large' />} label=
                {
                    <Box sx={[{ display: 'flex', alignItems: 'center' }, withUnavailableResourceStyle(article)]}>
                        <Box sx={{ flexGrow: 1 }}>
                            <Typography display='inline' component="h3" variant="h6" sx={{ mr: 1 }}>
                                {article.title}
                            </Typography>
                            {isAdmin &&
                                <Typography display='inline' sx={{ mr: 2 }}>
                                    {`(${article.requirementLabel})`}
                                </Typography>
                            }
                            <Typography display='inline'>
                                {article.subTitle}
                            </Typography>
                            
                        </Box>
                        {isAdmin &&
                            <IconButton sx={{ ml: 1 }} edge="end" onClick={(e) => showDeleteArticleDialog(e, article)} >
                                <DeleteIcon />
                            </IconButton>
                        }
                    </Box>
                }>
            </TreeItem>
        ));

        if (!isAdmin)
            return [...categories, ...articles];

        const addCategoryItem = (
            <TreeItem sx={{ mt: .5, mb: .5 }} onClick={(e) => showAddCategoryDialog(category.id)}
                nodeId={"addCat" + category.id} icon={<AddIcon color="primary" fontSize='large' />} label=
                {
                    <Typography color="primary" display='inline' component="h3" variant="h6" sx={{ mr: 2 }}>
                        Add Category
                    </Typography>
                }>
            </TreeItem>
        );

        return [...categories, ...articles, addCategoryItem];
    }

    function filterCategoriesRecursive(category, searchText)
    {
        let filteredArticles = category.articles.filter(a =>
        {
            if (a.title.toLowerCase().includes(searchText))
                return true;
            return a.subTitle !== null && a.subTitle.toLowerCase().includes(searchText);
        });

        // We need to search bottom up since we will want to include categories that
        // include articles or sub-categories that match the search string so map first
        let filteredSubCategories = category.subCategories
            .map(c => filterCategoriesRecursive(c, searchText))
            .filter(c => c.articles.length > 0 || c.subCategories.length > 0 || c.name.toLowerCase().includes(searchText));

        let childNodes = filteredSubCategories.flatMap(c => c.nodes);

        let filteredCategory =
        {
            ...category,
            subCategories: filteredSubCategories,
            articles: filteredArticles,
            nodes: [...childNodes, "cat" + category.id]
        };

        return filteredCategory;
    }

    function onSearch(searchText)
    {
        const hasSearchText = searchText && searchText.length > 0;
        if (!hasSearchText)
        {
            setFilteredRootCategory(null);
            return;
        }

        let filteredRootCategory = filterCategoriesRecursive(rootCategory, searchText.toLowerCase());
        setFilteredRootCategory(filteredRootCategory);
    }

    function onDownloadReadingList()
    {
        window.location.href = "https://question-bank-resources.s3.eu-west-2.amazonaws.com/Reading-list-2-1.xlsx";
    }

    // When we have search text and a filteredRootCategory expand all tree nodes
    const expandedNodes = filteredRootCategory?.nodes.filter(n => n !== null) ?? expanded;

    return (
        <Box>
            <Container maxWidth="md" sx={{ alignItems: 'centre' }}>
                <Typography sx={{ marginTop: 8 }} align="center" component="h1" variant="h4" gutterBottom color="primary">
                    {isAdmin ? "Resources Management" : "Resources"}
                </Typography>

                <Grid container justifyContent="flex-end" sx={{ mt: 4 }}>
                    <TextField label="Search" onChange={(e) => onSearch(e.target.value)} InputProps={{
                        endAdornment: (
                            <InputAdornment>
                                <SearchIcon />
                            </InputAdornment>
                        )
                    }} />
                </Grid>
                    
                {isAdmin &&

                    <Box sx={{ mt: 2, mb: 2 }}>
                        <Button variant="contained" onClick={handleClick} >New</Button>
                        <Menu id="basic-menu" anchorEl={anchorEl} open={open} onClose={handleClose}>
                            <MenuItem onClick={onCreateArticle}>Blank Article</MenuItem>
                            <MenuItem component="label">
                                Import Article from Doc<input value="" type="file" hidden onChange={onImportArticle} />
                            </MenuItem>
                            <MenuItem component="label">
                                Import Article From Html Zip<input value="" type="file" hidden onChange={onImportArticleAsHtml} />
                            </MenuItem>
                            <MenuItem component="label">
                                Import PDF Article<input value="" type="file" hidden onChange={onImportPDFArticle} />
                            </MenuItem>
                        </Menu>
                    </Box>
                }

                <TreeView expanded={expandedNodes} defaultCollapseIcon={<ExpandMoreIcon />} defaultExpandIcon={<ChevronRightIcon />}>
                    {CreateTreeViewResursive(filteredRootCategory ?? rootCategory)}
                </TreeView>

                {user &&
                    <Button sx={{ textTransform: "none", p: .5, textAlign: 'left' }} onClick={(e) => onDownloadReadingList()}>
                        <Box color="text.primary" sx={{ display: 'flex' }}>
                            <DownloadIcon sx={{ mt: .7, mr: .5 }} />
                            <Box align="left" alignItems="left" justify="left">
                                <Typography sx={{ mr: 1 }} component="h4" variant="h6" display='inline'>
                                    Reading List
                                </Typography>
                                <Typography sx={{ mr: 1 }} display='inline'>
                                    Click here to access our prioritised, colour coded reading list. This is a downloadable Microsoft Excel file. There are four sheets, one for each subspeciality.
                                </Typography>
                                <Typography sx={{ mr: 1 }} variant="subtitle2" display='inline'>
                                    Remember, this is just our opinion! Feel free to prioritise as you see fit. If you think we are missing something vital, please let us know.
                                </Typography>

                            </Box>
                        </Box>
                    </Button>
                }

            </Container>

            {dialogInfo && <GeneralDialog dialogInfo={dialogInfo} setDialogInfo={setDialogInfo} />}

        </Box>

    );
}