import { Autocomplete, TextField } from '@mui/material';
import axios from "axios";
import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import LoadingSpinnerCentred from "../Components/LoadingSpinnerCentred";
import EnhancedTable from "./EnhancedTable";

const questionHeadCells =
[
    {
        id: 'name',
        numeric: false,
        disablePadding: true,
        label: 'Name',
    },
    {
        id: 'category',
        numeric: false,
        disablePadding: false,
        label: 'Category',
    },
    {
        id: 'text',
        numeric: false,
        disablePadding: false,
        label: 'Text',
    },
    {
        id: 'status',
        numeric: false,
        disablePadding: false,
        label: 'Status',
    }
]

export default function QuestionsPage(params)
{
    const { aQP, setAQP } = params;
    const [questions, setQuestions] = useState(null);
    const [searchText, setSearchText] = useState("");
    const [requirements, setRequirements] = useState(null);
    let navigate = useNavigate();

    useEffect(() => {
        fetchQuestions();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function fetchQuestions()
    {
        setQuestions(null);
        axios.get('api/categories/requirements', { withCredentials: true }).then(res =>
        {
            setRequirements(res.data);

            axios.get('api/questions', { withCredentials: true }).then(result =>
            {
                const data = result.data.sort((a, b) =>
                {
                    // Sorting alphabetically, but to avoid this type of sorting: T1, T10, T100, T2
                    // Take out and compare the first matching number if the remaining text matches ('T' === 'T')
                    const numMatchA = a.name?.match(/\d+/) ?? null;
                    const numMatchB = b.name?.match(/\d+/) ?? null;
                    const numberA = numMatchA !== null && numMatchA.length > 0 ? numMatchA[0] : null;
                    const numberB = numMatchB !== null && numMatchB.length > 0 ? numMatchB[0] : null;
                    const aLetters = a.name?.replace(numberA, '') ?? '';
                    const bLetters = b.name?.replace(numberB, '') ?? '';
                    let result = aLetters.localeCompare(bLetters);
                    if (result === 0) // Compare the numbers if the letters match
                        result = parseInt(numberA) < parseInt(numberB) ? -1 : 1;
                    return result;
                }).map((q) =>
                ({
                    id: q.id,
                    name: q.name,
                    requirement: q.requirement,
                    category: q.category,
                    text: q.text,
                    columnData: [q.name, q.category, q.text, requirementLabel(q.requirement, res.data)],
                }));

                setQuestions(data);
            });
        })
        .catch(error =>
        {
            console.log(error?.response?.data?.message);
        });
    };

    function onAdd()
    {
        axios.post('api/questions/new', { withCredentials: true }).then(result =>
        {
            navigate("../questions-management-edit", { state: { editing: result.data } });
        });
    }

    function onDelete(questions)
    {
        const deleteDto = { Ids: questions.map(q => q.id) };
        axios.delete('api/questions/delete', { data: deleteDto }, { withCredentials: true }).then(result =>
        {
            fetchQuestions();
        });
    }

    function onEdit(question)
    {
        axios.get("api/questions/" + question.id, { withCredentials: true }).then(result =>
        {
            navigate("../questions-management-edit", { state: { editing: result.data } });
        });
    }

    function onImportFromDocument(event)
    {
        const formData = new FormData();
        formData.append('file', event.target.files[0]);
        const config = { headers: { 'content-type': 'multipart/form-data' } };

        setQuestions(null);
        return axios.post("api/questions/import", formData, config).then((response) =>
        {
            fetchQuestions();
        })
        .catch(error =>
        {
            fetchQuestions();
        });
    }

    function requirementLabel(requirementId, availableRequirements)
    {
        const reqs = availableRequirements ?? requirements;
        return reqs.find(s => s.id === requirementId)?.label;
    }

    function getRequirementLabel(uniqueRequirements)
    {
        if (!uniqueRequirements || uniqueRequirements.length === 0)
            return null;
        if (uniqueRequirements.length > 1)
            return "Mixed";
        return requirementLabel(uniqueRequirements[0]);
    }

    function onHandleQuestionStatusChanged(items, value)
    {
        const dto = { questionIds: items.map(i => i.id), requirement: value.id }
        axios.post("api/questions/update-status", dto, { withCredentials: true }).then((response) =>
        {
            fetchQuestions();
        })
        .catch(error =>
        {
            fetchQuestions();
        });
    }

    function extraHeaderComponents(selectedItems)
    {
        const allRequirements = selectedItems.map(x => x.requirement);
        const uniqueRequirement = [...new Set(allRequirements)];

        return (
            <Autocomplete disabled={selectedItems.length === 0} disableClearable disablePortal options={requirements}
                name="category" value={getRequirementLabel(uniqueRequirement)} onChange={(e,v) => onHandleQuestionStatusChanged(selectedItems, v)}
                renderInput={(params) => <TextField required {...params} label="Status" />} />
        );
    }

    if (!questions || !requirements)
        return <LoadingSpinnerCentred topMargin={true} />

    let filteredQuestions = questions;
    if (searchText && searchText.length > 0)
    {
        const lowerST = searchText.toLowerCase();
        filteredQuestions = questions.filter(q => q.name && (q.name.toLowerCase().includes(lowerST) || q.category.toLowerCase().includes(lowerST) || q.text.toLowerCase().includes(lowerST)));
    }

    const customButton = { label: "Import From Document", onClick: onImportFromDocument }
    return (
        <EnhancedTable title="Questions" rows={filteredQuestions} columns={questionHeadCells} setSearchText={setSearchText}
            onAdd={onAdd} onDelete={onDelete} onEdit={onEdit} customButton={customButton} extraHeaderComponents={extraHeaderComponents}
            page={aQP} setPage={setAQP}/>
    );
}