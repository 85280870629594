import React, { useState } from 'react';
import LockResetIcon from '@mui/icons-material/LockReset';
import axios from "axios";
import { Avatar, Button, TextField, Container, Typography, Alert, CircularProgress, Box } from "@mui/material";

export default function ForgottenPasswordPage()
{
    const [email, setEmail] = useState(null);
    const [alertInfo, setAlertInfo] = useState(null);
    const [sending, setSending] = useState(false);

    function handleSubmit(event)
    {
        event.preventDefault();
        setAlertInfo(null);
        setSending(true);

        axios.post('api/users/request-password-reset', { email: email }).then(response =>
        {
            setSending(false);
            setAlertInfo({ type: "info", message: "A password reset message was sent to your email addresss." });
        })
        .catch((error) =>
        {
            setSending(false);
            setAlertInfo({ type: "error", message: error?.response?.data?.message ?? "An error occured" });
        });
    }

    return (
        <Container component="main" maxWidth="xs">
            <Box sx={{ marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                    <LockResetIcon />
                </Avatar>

                <Typography component="h1" variant="h6">
                    To reset your password, please enter your email address below
                </Typography>
            </Box>

            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>

                <TextField id="email" name="email" autoComplete="email" label="Email Address" sx={{ mb: 2 }}
                    margin="normal" required fullWidth autoFocus onChange={(e) => setEmail(e.target.value)} />

                {
                    sending ?
                        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                            <CircularProgress />
                        </Box>
                    :
                    alertInfo ?
                        <Alert severity={alertInfo.type} > {alertInfo.message}</Alert>
                    :
                    <Button type="submit" fullWidth variant="contained" sx={{ mt: 2, mb: 2 }}>
                        Send password reset email
                    </Button>
                }

            </Box>
        </Container>
    );
}