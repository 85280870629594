import CardMembershipIcon from '@mui/icons-material/CardMembership';
import PersonIcon from '@mui/icons-material/Person';
import { Alert, Box, Button,  Container, FormControlLabel, Switch, TextField, Typography } from '@mui/material';
import axios from "axios";
import React, { useState } from 'react';
import { Link, Navigate, useLocation } from "react-router-dom";
import GeneralDialog from "../Components/GeneralDialog";
import SubscriptionInfo from '../Components/SubscriptionInfo';
import { getAccountLabel } from "../Utils/DataLabels";
import LoadingSpinnerCentred from "../Components/LoadingSpinnerCentred";

export default function UserProfile(params)
{
    const { user, setUser, darkMode } = params;
    const { state } = useLocation();

    const [busy, setBusy] = useState(false);
    const [alertInfo, setAlertInfo] = useState(state?.alertInfo);
    const [dialogInfo, setDialogInfo] = useState(null);

    function onDeleteAccount()
    {
        setDialogInfo(null);
        setBusy(true);

        axios.delete('api/users/delete', { withCredentials: true }).then(result =>
        {
            setAlertInfo({ type: "success", message: "Account deleted" });
            setUser(null);
            setBusy(false);
        })
        .catch((error) =>
        {
            setAlertInfo({ type: "error", message: "An error occured" });
            setBusy(false);
        });
    }

    function onToggleDarkMode()
    {
        // Update immeadiately rather than waiting for the database to remember this
        let copy = Object.assign({}, user);
        copy.darkMode = !user.darkMode;
        setUser(copy);

        const darkModeDto = { isDarkMode: !user.darkMode };
        axios.post('api/users/update-darkMode', darkModeDto, { withCredentials: true }).then(result =>
        {
            // No need to wait for the result, who really cares if it fails
            //setUser(result.data);
        });
    }

    function onShowDeleteConfirmation()
    {
        const dialogInfo =
        {
            title: "Delete Account",
            text: `Are you sure you want to delete your account?`,
            actions: [{ handler: onDeleteAccount, text: "Delete" }]
        };

        setDialogInfo(dialogInfo);
    }

    if (busy)
        return <LoadingSpinnerCentred topMargin={true} />;

    if (user === null)
        return <Navigate to="/"/>;

    const accountLabel = getAccountLabel(user);

    return (
        <Container component="main" maxWidth="md">

            <Box sx={{ marginTop: 8, display: 'flex', flexDirection: { xs: 'column', md: 'row' }, alignItems: 'top' }}>

                <Container maxWidth="xs" sx={{ mb: 6 }}>

                    <Box component="div" sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', mb: 1 }}>
                        <PersonIcon sx={{ mr: 1, fontSize: 30 }}/>
                        <Typography component="h2" variant="h5">Account</Typography>
                    </Box>

                    {alertInfo && <Alert severity={alertInfo.type}>{alertInfo.message}</Alert>}

                    <TextField id="name" name="name" variant="filled" value={user.name}
                        margin="normal" label="Name" fullWidth inputProps={{ readOnly: true, }} sx={{ mt: 2 }}/>

                    <TextField id="email" name="email" variant="filled" value={user.email} 
                        margin="normal" label="Email Address" fullWidth inputProps={{ readOnly: true, }} sx={{ mt: 2 }} />

                    <FormControlLabel control={<Switch checked={darkMode} onChange={onToggleDarkMode}
                        inputProps={{ 'aria-label': 'controlled' }} />} label="Dark Mode" sx={{ mt: 1 }}/>

                    <Button component={Link} to="/password_reset" fullWidth variant="contained" sx={{ mt: 2 }}>Change Password</Button>

                    <Button color="error" fullWidth variant="outlined" sx={{ mt: 2 }} onClick={onShowDeleteConfirmation}>
                        Delete Account
                    </Button>

                    {dialogInfo && <GeneralDialog dialogInfo={dialogInfo} setDialogInfo={setDialogInfo} />}

                </Container>

                <Container maxWidth="xs" sx={{ mb: 6 }}>

                    <Box component="div" sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', mb: 1 }}>
                        <CardMembershipIcon sx={{ mr: 1, fontSize: 30 }} />
                        <Typography component="h2" variant="h5">Subscription</Typography>
                    </Box>

                    <Box component="div" sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                        <Typography sx={{ mt: 1, mr: 1 }} inline component="h4" variant="h6" color="primary">Current Package:</Typography>
                        <Typography sx={{ mt: 1 }} inline component="h4" variant="h6">{accountLabel}</Typography>
                    </Box>
                    
                    <SubscriptionInfo user={user} setUser={setUser} setBusy={setBusy} setAlertInfo={setAlertInfo} setDialogInfo={setDialogInfo}/>
                    
                </Container>
            </Box>
        </Container>
    )
}