import { Box, Container, Typography } from '@mui/material';
import React from 'react';

export default function AboutPage(props) {

    return (
        <Container component="main" maxWidth="lg">
            <Box sx={{ marginTop: 8, display: 'flex', flexDirection: { xs: 'column', md: 'row' }, alignItems: 'top' }}>

                <Container maxWidth="md" sx={{ mb: 6 }}>

                    <Typography color="primary" component="h1" variant="h4" sx={{ fontWeight: 'bold' }}>
                        Don't just read the guidelines!
                    </Typography>

                    <Typography component="h2" variant="h5" sx={{ fontWeight: 'bold', mt:2 }}>
                        Path Question Bank is an independent website to help you learn the fundamentals of clinical haematology.
                    </Typography>

                    <Typography sx={{ fontWeight: 'bold', mt: 2 }}>
                        Our questions aim to capture the UK clinical haematology curriculum and are
                        focused on the FRCPath Part 1 exam curriculum. However, we welcome anyone
                        who just wants to improve their knowledge of clinical haematology practice.
                    </Typography>

                    <Typography sx={{ mt: 2 }}>
                        The website was borne out of frustration as every time our question writer
                        Dr Richard Buka asked how best to revise for the exam, he was told:
                    </Typography>

                    <Typography sx={{ fontWeight: 'bold', mt: 2 }}>
                        "JUST READ THE GUIDELINES"
                    </Typography>

                    <Typography sx={{ mt: 2 }}>Which guidelines?</Typography>
                    <Typography>What do I need to know?</Typography>
                    <Typography>How can I learn things?</Typography>
                    <Typography>What about things that are not in the guidelines?</Typography>

                    <Typography sx={{ mt: 2 }}>
                        {"When Rich\u2018s part 1 exam was postponed in March 2020, he sensed an opportunity " +
                        "to use his frustration and knowledge gained thus far to build an awesome " +
                        "revision tool to help you pass your exam."}
                    </Typography>

                    <Typography sx={{ mt: 2 }}>
                        Path Question Bank was borne out of a desire to streamline revision, allowing us
                        to do the hard work of identifying what you need to know and summarising things
                        in a way that is easy to digest, in bite size pieces. Our questions will test your
                        existing knowledge but allow you to build on it, allowing you to revise in the most
                        efficient way, and to allow you to get on with your busy life.
                    </Typography>

                    <Typography sx={{ mt: 2 }}>
                        More often than not, current learning resources are not aligned to help you learn
                        smart and pass that important exam that could significantly alter the trajectory
                        of the rest of your life.
                    </Typography>

                    <Typography sx={{ mt: 2 }}>
                        Path Question Bank does the heavy lifting for you and gives you a smart and fun
                        way of learning the information that you really need to know!
                    </Typography>

                    <Typography color="primary" component="h3" variant="h6" sx={{ fontWeight: 'bold', mt: 2 }}>
                        What this site is not
                    </Typography>

                    <Typography sx={{ mt: 2 }}>
                        Many of our questions are not realistic FRCPath MCQs or EMQs. Instead, they are
                        designed to develop your understanding of haematology and introduce you to a
                        world of resources for further reading.
                    </Typography>

                    <Typography sx={{ mt: 2 }}>
                        As half of the exam is essays, and these are the area with the highest failure
                        rate, our MCQs and EMQs are designed to give you the knowledge with which to
                        tackle the essays as well as the MCQs and EMQs. We are busy working on an
                        essay section to the site that will be better than you could ever imagine,
                        and this will be ready in coming weeks.
                    </Typography>

                    <Typography sx={{ mt: 2 }}>
                        The site is also not a clinical reference guide. You should not base clinical
                        management decisions on our questions and explanations. You must refer to
                        national and local guidelines. We do not accept any responsibility for clinical
                        eventualities that may ensue due to content contained on our site.
                    </Typography>

                </Container>

                <Container maxWidth="xs" sx={{mb: 4}}>

                    <Box sx={{ p: 3, border: 5, borderRadius: 3, borderColor: 'primary.main' }}>
                        <Typography sx={{ mt: 1, mr: 1 }} component="h4" variant="h6" color="primary">Our Team</Typography>

                        <Typography sx={{ fontWeight: 'bold', mt: 2 }}>
                            Dr Richard Buka
                        </Typography>
                        <Typography sx={{ mt: 1 }}>
                            Rich is a haematology registrar in the West Midlands. The site was his idea and he has developed
                            the questions. Any errors in the questions are his fault, based on naivity and lack of experience.
                            He tries to make up for this by being disturbingly keen, very geeky, and by being an obsessive fan
                            of evidence based medicine. Follow Rich on Twitter
                        </Typography>

                        <Typography sx={{ fontWeight: 'bold', mt: 2 }}>
                            Nick Jackson
                        </Typography>
                        <Typography sx={{ mt: 1 }}>
                            Nick is a vet. He likes to think this is like being a doctor for animals but really he just plays
                            with cats and dogs. When we say play, we really mean "does surgery". Nick is busy building a
                            partner website but regularly chips in with pearls of wisdom that are mostly ignored.
                        </Typography>

                        <Typography sx={{ fontWeight: 'bold', mt: 2 }}>
                            Lord Saviour Zenchovey
                        </Typography>
                        <Typography sx={{ mt: 1 }}>
                            Over time, even the most powerful rulers can become little more than legends, no longer
                            worshipped, no longer feared. But while some may speak of him in hushed whispers to
                            frighten the weak, there are still true believers who await his return.
                        </Typography>

                        <Box sx={{ mt: 2, display: 'flex', flexDirection: 'row', alignItems: 'left' }}>
                            <Typography inline sx={{ fontWeight: 'bold', mr: 1 }}>Contact us</Typography>
                            <a href='mailto:info@pathquestionbank.com'>info@pathquestionbank.com</a>
                        </Box>

                        <Box sx={{ mt: 2, display: 'flex', flexDirection: 'row', alignItems: 'left' }}>
                            <Typography inline sx={{ fontWeight: 'bold', mr:1 }}>Follow us on Twitter</Typography>
                            <a href='https://www.twitter.com/pathqbank'>@PathQBank</a>
                        </Box>

                    </Box>

                </Container>
            </Box>
        </Container>
    );
}