import { Alert, Box, Button, Container, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import axios from "axios";
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import LoadingSpinnerCentred from "../Components/LoadingSpinnerCentred";
import GeneralDialog from "../Components/GeneralDialog";

export default function EditUserPage(params)
{
    let navigate = useNavigate();
    const { state } = useLocation();
    const userData = state !== null ? state.userData : null;
    const [user, setUser] = useState(userData);
    const [accountTypes, setAccountTypes] = useState([]);
    const [dialogInfo, setDialogInfo] = useState(null);
    const [alertInfo, setAlertInfo] = useState(null);
    const [sending, setSending] = useState(null);

    useEffect(() => fetchUserConfigInfo(), []);

    function fetchUserConfigInfo()
    {
        axios.get('api/users/account-types', { withCredentials: true }).then(result =>
        {
            setAccountTypes(result.data);
        });
    }

    function handleSubmit(event)
    {
        event.preventDefault();

        const updateUserDto = { id: user.id, name: user.name, email: user.email, password: user.password, accountOverride: user.accountOverride }
        axios.put('api/users/update', updateUserDto, { withCredentials: true }).then(result =>
        {
            navigate(-1);
        });
    };

    function onHandlePropertyChange(event)
    {
        const { name, value } = event.target;
        setUser({ ...user, [name]: value });
    }

    function onSendPasswordReset()
    {
        const dialogInfo =
        {
            title: "Send password reset?",
            text: `Confirm you want to send a password reset email to ${user.email}`,
            actions: [{ handler: onConfirmSendPasswordReset, text: "Confirm" } ]
        };

        setDialogInfo(dialogInfo);
    }

    function onConfirmSendPasswordReset()
    {
        setDialogInfo(null);
        setSending(true);

        axios.post('api/users/reset-password', { userId: user.id }, { withCredentials: true }).then(result =>
        {
            setSending(false);
            setAlertInfo({ type: "success", message: `Successfully sent password reset email to ${user.email}` });
        })
        .catch((error) =>
        {
            setSending(false);
            setAlertInfo({ type: "error", message: error?.response?.data?.message ?? "An error occured" });
        });;
    }

    if (accountTypes === null || sending)
        return <LoadingSpinnerCentred topMargin={true}/>

    return (
        <Container component="main" maxWidth="xs">
            <Box onSubmit={handleSubmit} component="form" sx={{ marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>

                <TextField id="name" name="name" variant="outlined" value={user.name}
                    margin="normal" label="Name" fullWidth onChange={onHandlePropertyChange}/>

                <TextField id="email" name="email" variant="outlined" value={user.email}
                    margin="normal" label="Email Address" fullWidth onChange={onHandlePropertyChange}/>

                <TextField id="password" name="password" variant="outlined" 
                    margin="normal" label="Password" fullWidth onChange={onHandlePropertyChange} />

                <FormControl fullWidth sx={{ mt: 2 }}>
                    <InputLabel id="account-type">Account Type Override</InputLabel>
                    <Select label="Account Type Override" name="accountOverride" labelId="account-type" value={user.accountOverride}
                        onChange={onHandlePropertyChange}>
                        {accountTypes.map(at => {
                            return <MenuItem key={at.accountType} value={at.accountType}>{at.label}</MenuItem>
                        })}
                    </Select>
                </FormControl>

                {user.accountOverride !== 0 &&
                <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }} >
                    <Alert sx={{ mt: 1 }} severity="warning">This will override and ignore any user subscriptions</Alert>
                </Box>
                }

                <Button onClick={onSendPasswordReset} fullWidth variant="outlined" sx={{ mt: 2 }}>
                    Send Password Reset Email
                </Button>

                {alertInfo &&
                <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }} >
                    <Alert sx={{ mt: 1 }} severity={alertInfo.type}>{alertInfo.message}</Alert>
                </Box>
                }

                <Button type="submit" fullWidth variant="contained" sx={{ mt: 2, mb: 2 }}>
                    Update User
                </Button>

                {dialogInfo && <GeneralDialog dialogInfo={dialogInfo} setDialogInfo={setDialogInfo}/>}
                       
            </Box>
        </Container>
    );
}