import { Box, FormControlLabel, Switch, TextField } from '@mui/material';
import React, { useState } from 'react';
import CustomMarkdown from "../Components/CustomMarkdown";

export default function MarkdownEditView(params)
{
    const { onChange, children, label, name } = params; 
    const [visualPreview, setVisualPreview] = useState(false);

    return (
        <Box>
            <FormControlLabel control={<Switch checked={visualPreview} />}
                label="Visual preview" sx={{ mt: 4, mb: 2 }}
                onChange={(e) => setVisualPreview(!visualPreview)} />

            { visualPreview ?
                <Box sx={{ border: 1, borderRadius: '4px' }}>
                    <Box sx={{ m: 2 }}>
                        <CustomMarkdown children={children} />
                    </Box>
                </Box>
                :
                <TextField name={name} label={label} value={children}
                    variant="outlined" multiline fullWidth onChange={onChange} />
            }
        </Box>
    );
}