import { makeStyles } from "@material-ui/core/styles";
import TablePaginationUnstyled from '@mui/base/TablePaginationUnstyled';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SearchIcon from '@mui/icons-material/Search';
import { Box, Button, Checkbox, Grid, InputAdornment, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, TextField, Toolbar, Typography } from '@mui/material';
import Container from "@mui/material/Container";
import FormControlLabel from '@mui/material/FormControlLabel';
import IconButton from '@mui/material/IconButton';
import { alpha } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import Tooltip from '@mui/material/Tooltip';
import { styled } from '@mui/system';
import { visuallyHidden } from '@mui/utils';
import React, { useState } from 'react';

const useStyles = makeStyles({
    ellipsis: {
        maxWidth: "800px", // percentage also works
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis"
    }
});

const CustomTablePagination = styled(TablePaginationUnstyled)`
  & .MuiTablePaginationUnstyled-toolbar {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    margin: 5px;

    @media (min-width: 768px) {
      flex-direction: row;
      align-items: center;
    }
  }

  & .MuiTablePaginationUnstyled-selectLabel {
    margin: 0;
  }

  & .MuiTablePaginationUnstyled-displayedRows {
    margin: 0;

    @media (min-width: 768px) {
      margin-left: auto;
    }
  }

  & .MuiTablePaginationUnstyled-spacer {
    display: none;
  }

  & .MuiTablePaginationUnstyled-actions {
    display: flex;
    gap: 0.25rem;
  }
`;

function descendingComparator(a, b, orderBy)
{
    if (b[orderBy] < a[orderBy]) 
        return -1;
    if (b[orderBy] > a[orderBy])
        return 1;
    return 0;
}

function getComparator(order, orderBy)
{
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props)
{
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, headerCells } = props;

    const createSortHandler = (property) => (event) =>
    {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                <TableCell padding="checkbox">
                    <Checkbox color="primary" indeterminate={numSelected > 0 && numSelected < rowCount} checked={rowCount > 0 && numSelected === rowCount}
                              onChange={onSelectAllClick} inputProps={{ 'aria-label': 'select all desserts', }} />
                </TableCell>
                {headerCells.map((headCell) => (
                    <TableCell key={headCell.id} align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'} sortDirection={orderBy === headCell.id ? order : false}>
                        <TableSortLabel active={orderBy === headCell.id} direction={orderBy === headCell.id ? order : 'asc'} onClick={createSortHandler(headCell.id)}>
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

const EnhancedTableToolbar = (props) =>
{
    const { selected, title, onDelete, onSearch, extraHeaderComponents } = props;
    const titleStr = selected.length < 1 ? title : `${selected.length} selected`;
    const bgColourFunc = selected.length < 1 ? (theme) => null : (theme) => alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity);

    return (
        <Toolbar sx={{ pl: { sm: 2 }, pr: { xs: 1, sm: 1 }, bgcolor: bgColourFunc }}>
            
            <Grid container alignItems="center">
                <Grid item xs={7} >
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography color="inherit" variant="subtitle1" component="div">
                            {titleStr}
                        </Typography>
                        {selected.length > 0 &&
                            <Tooltip title="Delete">
                                <IconButton onClick={onDelete}>
                                    <DeleteIcon />
                                </IconButton>
                            </Tooltip>
                        }
                    </Box>
                </Grid>

                <Grid item xs={2}>
                    {extraHeaderComponents && extraHeaderComponents(selected)}
                </Grid>

                <Grid item xs={3}>
                    {onSearch &&
                        <TextField sx={{ ml: 2 }} label="Search" onChange={(e) => onSearch(e.target.value)} InputProps={{
                            endAdornment: (
                                <InputAdornment>
                                    <SearchIcon />
                                </InputAdornment>
                            )
                        }} />
                    }
                </Grid>
            </Grid>
            
        </Toolbar>
    );
};

export default function EnhancedTable(props)
{
    const { title, rows, columns, onAdd, onDelete, customButton, setSearchText, extraHeaderComponents, page, setPage } = props;
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('calories');
    const [selected, setSelected] = useState([]);
    //const [page, setPage] = useState(0);
    const [dense, setDense] = useState(false);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const classes = useStyles();

    const handleOnDelete = (event) =>
    {
        onDelete(selected);
    }

    const handleRequestSort = (event, property) =>
    {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) =>
    {
        setSelected(event.target.checked ? rows : []);
    };

    const handleClick = (event, name) =>
    {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];

        if (selectedIndex === -1)
            newSelected = newSelected.concat(selected, name);
        else if (selectedIndex === 0)
            newSelected = newSelected.concat(selected.slice(1));
        else if (selectedIndex === selected.length - 1)
            newSelected = newSelected.concat(selected.slice(0, -1));
        else if (selectedIndex > 0)
        {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) =>
    {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) =>
    {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangeDense = (event) =>
    {
        setDense(event.target.checked);
    };

    const isSelected = (id) =>
    {
        return selected.some(item => id === item.id);
    }

    // Avoid a layout jump when reaching the last page with empty rows.
    const maxPages = Math.round(rows.length / rowsPerPage);
    const targetPage = page > maxPages ? maxPages : page;
    const emptyRows = targetPage > 0 ? Math.max(0, (1 + targetPage) * rowsPerPage - rows.length) : 0;

    return (
        <Container component="main" maxWidth="xl" sx={{ mt: 4 }}>
            <Paper sx={{ width: '100%', mb: 2 }}>

                <EnhancedTableToolbar title={title} selected={selected} onDelete={handleOnDelete}
                    onSearch={setSearchText} extraHeaderComponents={extraHeaderComponents} />

                <TableContainer>
                    <Table aria-labelledby="tableTitle" size={dense ? 'small' : 'medium'} >

                        <EnhancedTableHead numSelected={selected.length} order={order} orderBy={orderBy} rowCount={rows.length}
                            onSelectAllClick={handleSelectAllClick} onRequestSort={handleRequestSort} headerCells={columns} />

                        <TableBody>
                            {/* if you don't need to support IE11, you can replace the `stableSort` call with: rows.slice().sort(getComparator(order, orderBy)) */}
                            {stableSort(rows, getComparator(order, orderBy))
                                .slice(targetPage * rowsPerPage, targetPage * rowsPerPage + rowsPerPage)
                                .map((row, index) =>
                                {
                                    const isItemSelected = isSelected(row.id);
                                    const labelId = `enhanced-table-checkbox-${index}`;

                                    return (
                                        <TableRow hover onClick={(event) => handleClick(event, row)} role="checkbox" aria-checked={isItemSelected}
                                                  tabIndex={-1} key={row.name} selected={isItemSelected}>
                                            <TableCell padding="checkbox">
                                                <Checkbox color="primary" checked={isItemSelected} inputProps={{ 'aria-labelledby': labelId }} />
                                            </TableCell>

                                            {row.columnData.map((cData, index) =>
                                            {
                                                if (index === 0)
                                                    return <TableCell key={index} component="th" id={cData} scope="row" padding="none">{row.name}</TableCell>
                                                return (
                                                    <TableCell key={index} align="left">
                                                        <Typography className={classes.ellipsis}>
                                                            {cData}
                                                        </Typography>
                                                    </TableCell>
                                                );
                                            })}

                                            {props.onEdit !== undefined  &&
                                                <TableCell padding="checkbox">
                                                    <Tooltip title="Edit">
                                                        <IconButton onClick={(event) => props.onEdit(row)}>
                                                            <EditIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                </TableCell>
                                            }

                                        </TableRow>
                                    );
                                })}
                            {emptyRows > 0 && (
                                <TableRow style={{ height: (dense ? 33 : 53) * emptyRows, }} >
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>

                <Stack direction="row" spacing={12}>
                    <IconButton sx={{ ml: 1 }} onClick={onAdd} edge="end" aria-label="add">
                        <AddIcon />
                    </IconButton>

                    <CustomTablePagination sx={{ justifyContent: "flex-end", mt: 2 }}
                        rowsPerPageOptions={[10, 25, 50]}
                        page={targetPage}
                        count={rows.length}
                        rowsPerPage={rowsPerPage}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        colSpan={3} />

                </Stack>
               
            </Paper>

            <Stack direction="row" spacing={2} >
                <FormControlLabel control={<Switch checked={dense} onChange={handleChangeDense} />} label="Dense padding" />
                {customButton &&
                    <Button component="label" variant="contained" sx={{ mt: 3, mb: 2 }}>
                        {customButton.label}<input value="" type="file" hidden onChange={customButton.onClick}/>
                    </Button>
                }
            </Stack>

        </Container>
    );
}